import AdminNavbar from "../../components/AdminNavbar";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
const Users = () => {
    const [admins, setAdmins] = useState()
    const [selected, setSelected] = useState({ rights: "-----" })
    const { user } = useAuthContext();




    const deleteUser = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/admin/` + selected.ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        if (response.ok) {
            const newAdimns = admins.filter(e => e.ID !== selected.ID)
            setAdmins(newAdimns)
            setSelected({ ...newAdimns[0] });
        }

    }

    const editRights = (right, operation) => {
        let index = 0;
        let temp_sel = selected;
        switch (right) {
            case 'a':
                index = 0
                break;
            case 'y':
                index = 1
                break;
            case 't':
                index = 2
                break;
            case 'g':
                index = 3;
                break;
            case 'f':
                index = 4;
                break;
            default:
                break;
        }
        if (operation === '+') {
            temp_sel.rights = temp_sel.rights.substring(0, index) + right + temp_sel.rights.substring(index + 1);

        } else {
            temp_sel.rights = temp_sel.rights.substring(0, index) + '-' + temp_sel.rights.substring(index + 1);
        }

        setSelected({ ...temp_sel })
    }

    const saveChanges = async () => {
        const changes = { rights: selected.rights }
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/admin/` + selected.ID, {
            method: 'POST',
            body: JSON.stringify(changes),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        if (response.ok) {

            let temp_admins = admins;
            temp_admins.find(e => e.ID === selected.ID).rights = selected.rights;
            setAdmins([...temp_admins])
        }
    }

    useEffect(() => {
        const fetchAdmins = async () => {

            const res = await fetch(`${process.env.REACT_APP_PATH}/api/admin`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await res.json()

            if (res.ok) {
                setAdmins(json)
            }
            setSelected({ ...json[0] });



        }
        if (user) {
            fetchAdmins();
        }

    }, [user])
    return (
        <div className="flex">
            <AdminNavbar />
            {admins && <div className="flex-1 p-8 ">
                <div>
                    <h1 className="text-3xl mb-5">Používatelia</h1>
                    <div className="w-full table">
                        <div className="table-row">
                            <div className="table-cell border-b pb-2 text-lg">Meno</div>
                            <div className="table-cell border-b pb-2 text-lg">E-mail</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Práva:</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Používatelia</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Turnaj</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Tímy</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Zápasy</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Fotky</div>

                        </div>
                        {admins.map(e => (
                            <div key={e.ID} className={`table-row pb-1 text-xl cursor-pointer hover:bg-gray-200 w-full ${(e.ID === selected.ID) ? " bg-gray-200 " : ""}`} onClick={x => setSelected({ ...e })}>

                                <div className="table-cell">{e.name}</div>
                                <div className="table-cell">{e.email}</div>
                                <div className="table-cell text-center"></div>
                                <div className="table-cell text-center">{(e.rights.includes("a")) ? <p className="text-green-600 font-extrabold">&#x2713;</p> : <p className="text-red-600 ">&#x2716;</p>}</div>
                                <div className="table-cell text-center">{(e.rights.includes("y")) ? <p className="text-green-600 font-bold">&#x2713;</p> : <p className="text-red-600 "> &#x2716;</p>}</div>
                                <div className="table-cell text-center">{(e.rights.includes("t")) ? <p className="text-green-600 font-bold">&#x2713;</p> : <p className="text-red-600 "> &#x2716;</p>}</div>
                                <div className="table-cell text-center">{(e.rights.includes("g")) ? <p className="text-green-600 font-bold">&#x2713;</p> : <p className="text-red-600 "> &#x2716;</p>}</div>
                                <div className="table-cell text-center">{(e.rights.includes("f")) ? <p className="text-green-600 font-bold">&#x2713;</p> : <p className="text-red-600 ">&#x2716;</p>}</div>

                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-10  w-full">
                    <h1 className="text-3xl mb-5">{selected.name} <span className="text-dark-text">({selected.email})</span></h1>
                    <div className="flex justify-between">
                        <div className="w-1/3 mr-2">
                            <h2 className="text-xl ">Právo upravovať:</h2>
                            <div className="flex items-center justify-between my-1">Používateľov{(selected.rights.includes("a")) ? <div className="flex items-center justify-between w-[10vw]"><p className="text-white px-2 py-1  bg-green-600 rounded-md">Povolené</p> <p className="text-red-800 border-b-2 border-red-800  px-2  cursor-pointer w-min font-bold" onClick={e => editRights('a', '-')}>Zakázať</p>  </div> : <div className="flex items-center justify-between w-[10vw]"><p className="text-white bg-red-800 px-2 py-1 rounded-md">Zakázané</p> <p className="text-green-600 border-b-2 border-green-600 px-2 cursor-pointer text-center" onClick={e => editRights('a', '+')} >Povoliť</p>  </div>}</div>
                            <div className="flex items-center justify-between my-1">Turnaj{(selected.rights.includes("y")) ? <div className="flex items-center justify-between w-[10vw]"><p className="text-white px-2 py-1  bg-green-600 rounded-md">Povolené</p>  <p className="text-red-800 border-b-2 border-red-800  px-2  cursor-pointer w-min font-bold" onClick={e => editRights('y', '-')}>Zakázať</p>  </div> : <div className="flex items-center justify-between w-[10vw]"><p className="text-white bg-red-800 px-2 py-1 rounded-md">Zakázané</p> <p className="text-green-600 border-b-2 border-green-600 px-2 cursor-pointer text-center" onClick={e => editRights('y', '+')} >Povoliť</p></div>}</div>
                            <div className="flex items-center justify-between my-1">Tímy{(selected.rights.includes("t")) ? <div className="flex items-center justify-between w-[10vw]"><p className="text-white px-2 py-1  bg-green-600 rounded-md">Povolené</p>  <p className="text-red-800 border-b-2 border-red-800  px-2  cursor-pointer w-min font-bold" onClick={e => editRights('t', '-')}>Zakázať</p>  </div> : <div className="flex items-center justify-between w-[10vw]"><p className="text-white bg-red-800 px-2 py-1 rounded-md">Zakázané</p> <p className="text-green-600 border-b-2 border-green-600 px-2 cursor-pointer text-center" onClick={e => editRights('t', '+')} >Povoliť</p> </div>}</div>
                            <div className="flex items-center justify-between my-1">Zápasy{(selected.rights.includes("g")) ? <div className="flex items-center justify-between w-[10vw]"><p className="text-white px-2 py-1  bg-green-600 rounded-md">Povolené</p>  <p className="text-red-800 border-b-2 border-red-800  px-2  cursor-pointer w-min font-bold" onClick={e => editRights('g', '-')}>Zakázať</p>  </div> : <div className="flex items-center justify-between w-[10vw]"><p className=" text-white bg-red-800 px-2 py-1 rounded-md">Zakázané</p> <p className="text-green-600 border-b-2 border-green-600 px-2 cursor-pointer text-center" onClick={e => editRights('g', '+')} >Povoliť</p> </div>}</div>
                            <div className="flex items-center justify-between my-1">Fotky{(selected.rights.includes("f")) ? <div className="flex items-center justify-between w-[10vw]"><p className="text-white px-2 py-1  bg-green-600 rounded-md">Povolené</p>  <p className="text-red-800 border-b-2 border-red-800  px-2  cursor-pointer w-min font-bold" onClick={e => editRights('f', '-')}>Zakázať</p>  </div> : <div className="flex items-center justify-between w-[10vw]"><p className="text-white bg-red-800 px-2 py-1 rounded-md">Zakázané</p> <p className="text-green-600 border-b-2 border-green-600 px-2 cursor-pointer text-center" onClick={e => editRights('f', '+')} >Povoliť</p> </div>}</div>

                        </div>
                        <div className="cursor-pointer flex w-1/3 justify-center items-center">
                            <div className="bg-red-800 px-2 py-1 rounded-md text-white h-min" onClick={deleteUser}>Odstrániť používateľa</div>
                        </div>
                    </div>



                    <div className="flex w-[20vw] mx-auto justify-around">
                        <div className={`  text-white p-2 rounded-md ${(selected.rights === admins.find(e => e.ID === selected.ID).rights) ? "cursor-not-allowed bg-gray-400" : "cursor-pointer bg-web-gray"}`} onClick={x => saveChanges()}>Uložiť zmeny</div>
                        <div className={`  text-white p-2 rounded-md ${(selected.rights === admins.find(e => e.ID === selected.ID).rights) ? "cursor-not-allowed bg-gray-400" : "cursor-pointer bg-web-gray"}`} onClick={x => setSelected({ ...admins.find(e => e.ID === selected.ID) })}>Zrušiť zmeny</div>
                    </div>
                </div>
            </div>}
            {!admins &&
                <div className="w-[80vw] ml-[15vw] mt-16 p-8 h-screen">
                    <h1>No users found</h1>
                </div>
            }
        </div >
    );
}

export default Users;