import { useEffect, useState } from "react";
import { useYearContext } from "../../hooks/useYearContext";
import { format } from "date-fns";
import { sk } from "date-fns/locale";
import Loader from "../../components/Loader";


const UserSchedule = () => {
    const { current } = useYearContext()

    const [events, setEvents] = useState([])
    const [days, setDays] = useState([])

    useEffect(() => {
        const getEvents = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/year/event?year=${current.year}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const json = await response.json()
            if (response.ok) {
                setEvents(json)
                let temp = []
                for (let i = 0; i < datediff(new Date(current.start), new Date(current.end)); i++) {
                    let date = new Date(current.start)
                    date.setDate(date.getDate() + i)
                    temp.push(date)
                }

                function datediff(first, second) {
                    return Math.round((second - first) / (1000 * 60 * 60 * 24)) + 1;
                }
                let x = [];
                let y = []
                for (let i = 0; i < temp.length; i++) {
                    x.push(0)
                    y.push('')

                }
                setDays(temp)
            }
        }
        if (current) {
            getEvents()
        }

    }, [current])

    return (
        <div className=" w-[90vw] md:w-[70vw] xl:w-[46vw] mx-auto">
            {(events.length > 0 && days.length > 0) && days.map((e, i) => (
                <div key={i} className="my-12 bg-white p-8 border border-borders">
                    <h2 className="text-xl font-semibold mb-5">{format(days[i], 'eeee', { locale: sk }).charAt(0).toUpperCase() + format(days[i], "eeee", { locale: sk }).slice(1)}</h2>
                    <div className="table">
                        {events.filter(x => format(new Date(x.date_time), "M.d") === format(days[i], "M.d")).map((f, j) => (
                            <div key={f.ID} className="table-row ">
                                <div className="text-md pb-2 pr-7  border-r table-cell border-borders ">
                                    {f.date_time.substring(f.date_time.indexOf("T") + 1, f.date_time.indexOf("T") + 1 + 5)}
                                </div>
                                <div className=" pb-2 px-8 table-cell">
                                    {f.event}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            {!(events.length > 0 && days.length > 0) && <Loader />}
        </div>
    );
}

export default UserSchedule;