import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

const AdminNavbar = () => {
    const { logout } = useLogout()
    const { user } = useAuthContext();
    return (
        <div className="hidden lg:block w-[15vw] min-w-[180px]">
            <div className="bg-web-gray  w-[15vw] fixed min-w-[180px] h-screen  top-0 left-0   py-16 px-4 text-white flex flex-col justify-between z-50">
                <div>
                    <p className="text-center  pt-3 text-accent"><Link className="text-3xl" to={'/admin'} >AdminPanel</Link></p>
                    {user && <p className="text-center relative -top-2">{user.name}</p>}
                    <div className="mt-3 flex flex-col justify-around child-hover:text-accent child:">
                        <div className="border-b border-dark-text"></div>
                        <Link to={'/admin'} className="cursor-pointer text-xl  border-b border-dark-text py-2">Prehľad</Link>
                        {user.rights.includes('a') && <Link to={'/admin/users'} className="cursor-pointer text-xl  border-b border-dark-text py-2">Používatelia</Link>}
                        {user.rights.includes('y') && <Link to={'/admin/tournament'} className="cursor-pointer text-xl border-b border-dark-text py-2">Turnaj</Link>}
                        {user.rights.includes('g') && <Link to={'/admin/games'} className="cursor-pointer text-xl  border-b border-dark-text py-2">Zápasy</Link>}
                        {user.rights.includes('t') && <Link to={'/admin/teams'} className="cursor-pointer text-xl  border-b border-dark-text py-2">Tímy</Link>}
                        {user.rights.includes('f') && <Link to={'/admin/gallery'} className="cursor-pointer text-xl  border-b border-dark-text py-2">Galéria</Link>}
                    </div>
                </div>

                <div className="text-center">

                    <button className="btn mx-auto rounded " onClick={logout}>Odhlásiť</button>
                </div>
            </div>

        </div>
    );
}

export default AdminNavbar;