import { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import { useYearContext } from "../../hooks/useYearContext"
import { useAuthContext } from "../../hooks/useAuthContext";
import { format } from "date-fns"
import sk from "date-fns/locale/sk"
import { Link } from "react-router-dom";
const Games = () => {

    const { user } = useAuthContext();
    const { current } = useYearContext();

    const [teams, setTeams] = useState([])
    const [fields, setFields] = useState([])

    const [nmTeam1, setNmTeam1] = useState()
    const [nmTeam2, setNmTeam2] = useState()
    const [nmDateTime, setNmDateTime] = useState()
    const [nmField, setNmField] = useState()
    const [nmText, setNmText] = useState("group")

    const [games, setGames] = useState([])
    const [codes, setCodes] = useState([])


    const [playoffs, setPlayoffs] = useState([])
    useEffect(() => {
        const getTeams = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams?year=${current.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setTeams(json)
            } else {
                alert(json.error)
            }
        }





        const getGroupCodes = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/group/codes?year=${current.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            const json = await response.json()
            if (response.ok) {
                setCodes(json)
                console.log(json);
            } else {
                alert(json.error)
            }
        }



        const getFields = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/field`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setFields(json)
            } else {
                alert(json.error)
            }
        }

        const getGames = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/game?year=${current.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setGames(json)
            } else {
                alert(json.error)
            }
        }
        const getPlayoffs = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/playoff?year=${current.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                console.log(json);
                setPlayoffs(json)
            } else {
                alert(json.error)
            }
        }

        getGames()
        getGroupCodes()
        getFields()
        getTeams();
        getPlayoffs()
    }, [])




    const addMatch = async (e) => {
        e.preventDefault()
        if (!nmTeam1) {
            alert("Zadaj tím 1!")
            return
        }
        if (!nmTeam2) {
            alert("Zadaj tím 2!")
            return
        }
        if (nmDateTime === 0) {
            alert("Zadaj dátum a čas!")
            return
        }
        console.log(nmText);
        console.log(teams.find(x => x.group_code === nmTeam1));
        console.log(teams.find(x => x.group_code === nmTeam2));
        let team_a_id = 0
        let team_b_id = 0
        if (teams.find(x => x.group_code === nmTeam1)) {
            console.log(nmTeam1);
            console.log(teams.find(x => x.group_code === nmTeam1).ID);
            team_a_id = teams.find(x => x.group_code === nmTeam1).ID
        }
        if (teams.find(x => x.group_code === nmTeam2)) {
            team_b_id = teams.find(x => x.group_code === nmTeam2).ID
        }

        console.log({ team1: nmTeam1, team2: nmTeam2, time: nmDateTime, field: nmField, year: current.year, team_a_id, team_b_id });

        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/text`, {
            method: 'POST',
            body: JSON.stringify({ team1: nmTeam1, team2: nmTeam2, time: nmDateTime, field: nmField, year: current.year, team_a_id, team_b_id, text: nmText }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            let temp = games;
            temp.push(json[0])
            console.log(json[0]);
            setGames([...temp])
            setNmTeam1()
            setNmTeam2()
            setNmDateTime(0)
            setNmField(1)
            setNmText("group")
        } else {
            alert(json.error)
        }
    }

    const [confirm, setConfirm] = useState(-1)

    const deleteGame = async (i) => {


        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/${games[i].ID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            let temp = games.filter(x => x.ID !== games[i].ID);
            setGames(temp)
        } else {
            alert(json.error)
        }
    }

    const [poTeam, setPoTeam] = useState(0)
    const [poText1, setPoText1] = useState("")
    const [poText2, setPoText2] = useState("")
    const [poDateTime, setPoDateTime] = useState(0)
    const [poField, setPoField] = useState()
    const [poText, setPoText] = useState("-")


    const [confirm2, setConfirm2] = useState(-1)
    const addPlayOff = async (e) => {
        e.preventDefault()
        if (poDateTime === 0) {
            alert("Zadaj dátum a čas!")
            return
        }
        if (poTeam) {

        }


        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/playoff`, {
            method: 'POST',
            body: JSON.stringify({ text_a: poText1, text_b: poText2, time: poDateTime, field: poField, year: current.year, team_a_id: poTeam, text: poText }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            let temp = playoffs;
            temp.push(json[0])
            setPlayoffs([...temp])
            setPoTeam()
            setPoText1("")
            setPoText2("")
            setPoDateTime(0)
            setPoField(0);
            setPoText("-")
        } else {
            alert(json.error)
        }
    }

    const deletePlayOff = async (i) => {


        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/playoff/${playoffs[i].ID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            let temp = playoffs.filter(x => x.ID !== playoffs[i].ID);
            setPlayoffs(temp)
        } else {
            alert(json.error)
        }
    }


    return (
        <div className="flex">
            <AdminNavbar />
            <div className="flex-1 max-w-[100vw] md:max-w-none p-8 ">
                <h1 className="text-3xl mb-5">Zápasy</h1>
                {user.rights.includes("y") && <form className="flex flex-col w-full items-center justify-center border-b border-t py-5" onSubmit={addMatch}>
                    <div className="flex flex-col md:flex-row items-center justify-center child:my-2 md:child:my-0" >
                        <div className="text-xl">
                            <p className="text-center text-xl">Tím 1</p>
                            <select className="text-xl p-2" value={nmTeam1} onChange={e => setNmTeam1(parseInt(e.target.value))}>
                                <option value={''}></option>
                                {codes.filter(x => x.ID !== nmTeam2 && x.name !== '-').map(e => (
                                    <option key={e.ID} className="text-xl" value={e.ID}>{e.name}({teams.find(x => x.group_code === e.ID) ? teams.find(x => x.group_code === e.ID).name : ""})</option>
                                ))}
                            </select>
                        </div>
                        <div className="text-xl mx-8 ">
                            <p className="block text-center text-xl">Čas a miesto</p>
                            <div className="flex flex-col child:my-2">
                                <input type="datetime-local" value={nmDateTime} onChange={e => setNmDateTime(e.target.value)} />
                                <select className="text-xl p-2 text-center" value={nmField} onChange={e => setNmField(parseInt(e.target.value))}>
                                    {fields.map(e => (
                                        <option key={e.ID} className="text-xl" value={e.ID}>{e.field_name !== '-' && 'Ihrisko'} {e.field_name}</option>
                                    ))}
                                </select>
                                <select className="text-xl p-2 text-center" value={nmText} onChange={e => setNmText(e.target.value)}>
                                    <option className="text-xl" value={"group"}>Skupinový</option>
                                    {games.filter(x => x.text === "QF1")[0] === undefined && <option className="text-xl" value={"QF1"}>Štvrťfinále 1</option>}
                                    {games.filter(x => x.text === "QF2")[0] === undefined && <option className="text-xl" value={"QF2"}>Štvrťfinále 2</option>}
                                    {games.filter(x => x.text === "QF3")[0] === undefined && <option className="text-xl" value={"QF3"}>Štvrťfinále 3</option>}
                                    {games.filter(x => x.text === "QF4")[0] === undefined && <option className="text-xl" value={"QF4"}>Štvrťfinále 4</option>}
                                    {games.filter(x => x.text === "SF1")[0] === undefined && <option className="text-xl" value={"SF1"}>Semifinále 1</option>}
                                    {games.filter(x => x.text === "SF2")[0] === undefined && <option className="text-xl" value={"SF2"}>Semifinále 2</option>}
                                    {games.filter(x => x.text === "Final")[0] === undefined && <option className="text-xl" value={"Final"}>Finále</option>}
                                    {games.filter(x => x.text === "o3")[0] === undefined && <option className="text-xl" value={"o3"}>O 3 miesto</option>}
                                    {games.filter(x => x.text === "o5")[0] === undefined && <option className="text-xl" value={"o5"}>O 5 miesto</option>}
                                    {games.filter(x => x.text === "o7")[0] === undefined && <option className="text-xl" value={"o7"}>O 7 miesto</option>}
                                </select>
                            </div>

                        </div>
                        <div className="text-xl">
                            <p className="text-center text-xl">Tím 2</p>
                            <select className="text-xl p-2" value={nmTeam2} onChange={e => setNmTeam2(parseInt(e.target.value))}>
                                <option value={''}></option>
                                {codes.filter(x => x.ID !== nmTeam1 && x.name !== '-').map(e => (
                                    <option key={e.ID} className="text-xl" value={e.ID}>{e.name}({teams.find(x => x.group_code === e.ID) ? teams.find(x => x.group_code === e.ID).name : ""})</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <button className="btn text-xl">Pridať</button>
                </form>}

                {codes.length > 0 && <div className="hidden md:block">
                    {games.map((e, i) => (
                        <div key={e.ID} className="flex justify-between items-center border-b hover:bg-borders cursor-pointer">
                            <div className="w-52">
                                {e.status === 'scheduled' && <div className="flex items-center text-dark-text"><div className=" mx-1 p-1 bg-dark-text rounded-full"></div><div className="text-lg">Plánovaný</div></div>}
                                {e.status === 'live' && <div className="flex items-center  text-accent"><div className=" mx-1 p-1 bg-accent  rounded-full"></div><div className="text-lg">Prebieha</div></div>}
                                {e.status === 'finished' && <div className="flex items-center  text-red-700"><div className=" mx-1 p-1 bg-red-700 rounded-full"></div><div className="text-lg">Ukončený</div></div>}
                            </div>
                            <Link to={`/admin/games/${e.ID}`} className="flex items-center justify-center w-[80%] ">
                                <div className="text-xl">
                                    <p className="text-center text-xl min-w-[13rem] ">{e.team_a_name} ({codes.find(x => x.ID === e.code_a).name})</p>
                                </div>
                                <div className="text-2xl text-accent">{e.team_a_goals}</div>
                                <div className="text-xl mx-8 text-center py-1">
                                    <p className="text-xl">{format(new Date(e.time), 'eeee', { locale: sk }).charAt(0).toUpperCase() + format(new Date(e.time), 'eeee', { locale: sk }).slice(1)} {e.time.substring(e.time.indexOf("T") + 1, e.time.indexOf("T") + 1 + 5)}</p>
                                    <p className="text-xl">Ihrisko  {e.field_name}</p>
                                    <p className="text-xl">
                                        {e.text === "group" ? "Skupinový" : ""}
                                        {e.text === "o3" ? "O 3. miesto" : ""}
                                        {e.text === "o5" ? "O 5. miesto" : ""}
                                        {e.text === "o7" ? "O 7. miesto" : ""}
                                        {(e.text === "QF1" || e.text === "QF2" || e.text === "QF3" || e.text === "QF4") ? " Štvrťfinále" : ""}
                                        {(e.text === "SF1" || e.text === "SF2") ? " Semifinále" : ""}
                                        {e.text === "Final" ? "Finále" : ""}
                                    </p>
                                </div>
                                <div className="text-2xl text-accent">{e.team_b_goals}</div>
                                <div confrimclassName="text-xl">
                                    <p className="text-center text-xl min-w-[13rem]">{e.team_b_name} ({codes.find(x => x.ID === e.code_b).name})</p>
                                </div>
                            </Link>
                            {user.rights.includes("y") && <div className="w-52">
                                {confirm !== i && <button className="btn !bg-red-700 z-50" onClick={e => setConfirm(i)}>Delete</button>}
                                {confirm === i &&
                                    <div className="flex">
                                        <button className="btn !bg-transparent border border-red-600 !text-red-600 mr-5 " onClick={e => setConfirm(-1)}>Cancel</button>
                                        <button className="btn !bg-red-700" onClick={e => { deleteGame(i); setConfirm(-1) }}>Delete</button>

                                    </div>
                                }
                            </div>}

                        </div>
                    ))}
                </div>}
                {codes.length > 0 && <div className="md:hidden">
                    {games.map((e, i) => (
                        <div key={e.ID} className="border-b hover:bg-borders cursor-pointer">
                            <div className="text-center flex justify-center">
                                {e.status === 'scheduled' && <div className="flex items-center text-dark-text"><div className=" mx-1 p-1 bg-dark-text rounded-full"></div><div className="text-lg">Plánovaný</div></div>}
                                {e.status === 'live' && <div className="flex items-center  text-accent"><div className=" mx-1 p-1 bg-accent  rounded-full"></div><div className="text-lg">Prebieha</div></div>}
                                {e.status === 'finished' && <div className="flex items-center  text-red-700"><div className=" mx-1 p-1 bg-red-700 rounded-full"></div><div className="text-lg">Ukončený</div></div>}
                            </div>
                            <Link to={`/admin/games/${e.ID}`} className="flex flex-col items-center justify-center  ">

                                <div className="flex justify-center text-center items-center">
                                    <p className="w-52 text-xl whitespace-nowrap">{e.team_a_name} ({codes.find(x => x.ID === e.code_a).name}) </p>
                                    <p className="px-2 text-2xl text-accent">{e.team_a_goals}</p>
                                    <p className="px-2 text-2xl text-accent">{e.team_b_goals}</p>
                                    <p className="w-52 text-xl whitespace-nowrap">{e.team_b_name} ({codes.find(x => x.ID === e.code_b).name}) </p>

                                </div>

                                <div className="text-xl text-center text-dark-text">
                                    <p className="text-xl">Ihrisko  {e.field_name} - {format(new Date(e.time), 'eeee', { locale: sk }).charAt(0).toUpperCase() + format(new Date(e.time), 'eeee', { locale: sk }).slice(1)} {e.time.substring(e.time.indexOf("T") + 1, e.time.indexOf("T") + 1 + 5)} -
                                        <span className="text-xl">
                                            {e.text === "group" ? " Skupinový" : ""}
                                            {e.text === "o3" ? " O 3. miesto" : ""}
                                            {e.text === "o5" ? " O 5. miesto" : ""}
                                            {e.text === "o7" ? " O 7. miesto" : ""}
                                            {(e.text === "QF1" || e.text === "QF2" || e.text === "QF3" || e.text === "QF4") ? " Štvrťfinále" : ""}
                                            {(e.text === "SF1" || e.text === "SF2") ? " Semifinále" : ""}
                                            {e.text === "Final" ? " Finále" : ""}
                                        </span> </p>
                                    <p className="text-xl"></p>
                                </div>
                            </Link>
                            {user.rights.includes("y") && <div className="w-full flex justify-center my-3 ">
                                {confirm !== i && <button className="btn !bg-red-700 z-40" onClick={e => setConfirm(i)}>Delete</button>}
                                {confirm === i &&
                                    <div className="flex">
                                        <button className="btn !bg-transparent border border-red-600 !text-red-600 mr-5 " onClick={e => setConfirm(-1)}>Cancel</button>
                                        <button className="btn !bg-red-700" onClick={e => { deleteGame(i); setConfirm(-1) }}>Delete</button>
                                    </div>
                                }
                            </div>}

                        </div>
                    ))}
                </div>}
                <h1 className="text-3xl my-5">Playoff (placeholders)</h1>
                {user.rights.includes("y") && <form className="flex flex-col w-full items-center justify-center border-b border-t py-5" onSubmit={addPlayOff}>
                    <div className="flex flex-col md:flex-row items-center justify-center child:my-2 md:child:my-0" >
                        <div className="text-xl">
                            <h1>Zadaj Tím ALEBO text 1 </h1>
                            <p className="text-center text-xl">Tím</p>
                            <select className="text-xl p-2" value={poTeam} onChange={e => setPoTeam(parseInt(e.target.value))}>
                                <option value={0}></option>
                                {teams.filter(x => x.name !== '-').map(e => (
                                    <option key={e.ID} className="text-xl" value={e.ID}>{e.name}({teams.find(x => x.group_code === e.ID) ? teams.find(x => x.group_code === e.ID).name : ""})</option>
                                ))}
                            </select>

                            <p className="text-center text-xl">Text 1</p>
                            <input type="text" className="py-2" value={poText1} onChange={e => setPoText1(e.target.value)} />
                        </div>
                        <div className="text-xl mx-8 ">
                            <p className="block text-center text-xl">Čas a miesto</p>
                            <div className="flex flex-col child:my-2">
                                <input type="datetime-local" value={poDateTime} onChange={e => setPoDateTime(e.target.value)} />
                                <select className="text-xl p-2 text-center" value={poField} onChange={e => setPoField(parseInt(e.target.value))}>
                                    {fields.map(e => (
                                        <option key={e.ID} className="text-xl" value={e.ID}>{e.field_name !== '-' && 'Ihrisko'} {e.field_name}</option>
                                    ))}
                                </select>
                                <select className="text-xl p-2 text-center" value={poText} onChange={e => setPoText(e.target.value)}>
                                    {<option className="text-xl" value={"-"}>-</option>}
                                    {playoffs.filter(x => x.text === "QF1")[0] === undefined && <option className="text-xl" value={"QF1"}>Štvrťfinále 1</option>}
                                    {playoffs.filter(x => x.text === "QF2")[0] === undefined && <option className="text-xl" value={"QF2"}>Štvrťfinále 2</option>}
                                    {playoffs.filter(x => x.text === "QF3")[0] === undefined && <option className="text-xl" value={"QF3"}>Štvrťfinále 3</option>}
                                    {playoffs.filter(x => x.text === "QF4")[0] === undefined && <option className="text-xl" value={"QF4"}>Štvrťfinále 4</option>}
                                    {playoffs.filter(x => x.text === "SF1")[0] === undefined && <option className="text-xl" value={"SF1"}>Semifinále 1</option>}
                                    {playoffs.filter(x => x.text === "SF2")[0] === undefined && <option className="text-xl" value={"SF2"}>Semifinále 2</option>}
                                    {playoffs.filter(x => x.text === "Final")[0] === undefined && <option className="text-xl" value={"Final"}>Finále</option>}
                                    {playoffs.filter(x => x.text === "o3")[0] === undefined && <option className="text-xl" value={"o3"}>O 3 miesto</option>}
                                    {playoffs.filter(x => x.text === "o5")[0] === undefined && <option className="text-xl" value={"o5"}>O 5 miesto</option>}
                                    {playoffs.filter(x => x.text === "o7")[0] === undefined && <option className="text-xl" value={"o7"}>O 7 miesto</option>}
                                </select>
                            </div>

                        </div>
                        <div className="text-xl">
                            <p className="text-center text-xl">Text 2</p>
                            <input type="text" className="py-2" value={poText2} onChange={e => setPoText2(e.target.value)} />
                        </div>
                    </div>
                    <button className="btn text-xl">Pridať</button>
                </form>}
                {(codes.length > 0 && user.rights.includes("y")) && <div className="hidden md:block">
                    {playoffs.map((e, i) => (
                        <div key={e.ID} className="flex justify-between items-center border-b hover:bg-borders cursor-pointer">
                            <div className="w-52"></div>
                            <div className="flex items-center justify-center w-[80%] ">
                                <div className="text-xl">
                                    <p className="text-center text-xl min-w-[13rem] ">{e.team_a_name}</p>
                                </div>
                                <div className="text-2xl text-accent">{e.team_a_goals}</div>
                                <div className="text-xl mx-8 text-center py-1">
                                    <p className="text-xl">{format(new Date(e.time), 'eeee', { locale: sk }).charAt(0).toUpperCase() + format(new Date(e.time), 'eeee', { locale: sk }).slice(1)} {e.time.substring(e.time.indexOf("T") + 1, e.time.indexOf("T") + 1 + 5)}</p>
                                    <p className="text-xl">Ihrisko  {e.field_name}</p>
                                    <p className="text-xl">
                                        {e.text}
                                    </p>
                                </div>
                            </div>
                            <div className="w-52">
                                {confirm2 !== i && <button className="btn !bg-red-700 z-50" onClick={e => setConfirm2(i)}>Delete</button>}
                                {confirm2 === i &&
                                    <div className="flex">
                                        <button className="btn !bg-transparent border border-red-600 !text-red-600 mr-5 " onClick={e => setConfirm2(-1)}>Cancel</button>
                                        <button className="btn !bg-red-700" onClick={e => { deletePlayOff(i); setConfirm2(-1) }}>Delete</button>

                                    </div>
                                }
                            </div>

                        </div>
                    ))}
                </div>}
            </div>

        </div >
    );
}

export default Games;