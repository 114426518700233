import { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import { useYearContext } from "../../hooks/useYearContext"
import { useAuthContext } from "../../hooks/useAuthContext";

const Teams = () => {

    const { user } = useAuthContext();
    const { current } = useYearContext()
    const [teams, setTeams] = useState()
    const [groups, setGroups] = useState()
    const [newTeamName, setNewTeamName] = useState('')
    const [editedTeamName, setEditedTeamName] = useState('')
    const [selected, setSelected] = useState()
    const [deleteTeam, setDeleteTeam] = useState('');
    const [players, setPlayers] = useState([])
    const [codes, setCodes] = useState([])
    useEffect(e => {
        const getTeams = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams?year=${current.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setTeams(json)
                setSelected(json[0])
                getGroups()
                getGroupCodes()
            } else {
                alert(json.error)
            }
        }

        getTeams();
    }, [])

    const createTeam = async (e) => {
        e.preventDefault()
        if (newTeamName.length <= 3) {
            alert("Názov tímu je príliš krátky")
        } else {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams`, {
                method: 'POST',
                body: JSON.stringify({ name: newTeamName, year: current.year }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                var temp = teams;
                temp.push(json[0])
                console.log(temp);
                setTeams([...temp])
                setSelected(json[0])
                setNewTeamName('')


            } else {
                alert(json.error)
            }
        }

    }
    const changeTeamName = async (e) => {
        e.preventDefault()
        if (editedTeamName.length <= 3) {
            alert("Názov tímu je príliš krátky")
        } else {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams/${selected.ID}`, {
                method: 'PATCH',
                body: JSON.stringify({ name: editedTeamName }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                var temp = teams
                temp.find(e => e.ID === selected.ID).name = editedTeamName;

                setTeams([...temp])
                temp = selected;
                temp.name = editedTeamName;
                setSelected({ ...temp })


            } else {
                alert(json.error)
            }
        }

    }

    const getGroupCodes = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/group/codes?year=${current.year}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const json = await response.json()
        if (response.ok) {
            setCodes(json)
            console.log(json);
        } else {
            alert(json.error)
        }
    }

    const getGroups = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/group?year=${current.year}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            setGroups(json)
        }
    }

    const [newGroup, setNewGroup] = useState()
    useEffect(() => {
        if (selected) {
            setNewGroup(selected.group_code)
            setEditedTeamName(selected.name)
            setDeleteTeam('')

            if (!players.find(x => x.team_id === selected.ID)) {
                getPlayers(selected.ID);
            }



        }

    }, [selected])

    const [deleted, setDeleted] = useState(true)
    useEffect(() => {
        if (teams) {
            if (deleted) {
                if (teams[0]) {
                    setSelected(teams[0])
                }

            } else {
                setDeleted(true)
            }

        }

    }, [teams])


    const saveNewGroup = async (e) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams/${selected.ID}`, {
            method: 'PATCH',
            body: JSON.stringify({ group_code: newGroup }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        if (response.ok) {
            var temp = teams
            temp.find(e => e.ID === selected.ID).group_code = newGroup;
            temp.find(e => e.ID === selected.ID).group_code_name = codes.find(e => e.ID === newGroup).name;
            setTeams([...temp])
        }
    }

    const deleteThisTeam = async (e) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams/${selected.ID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            var temp = teams.filter(e => e.ID !== selected.ID)
            setTeams([...temp])
        } else {

            alert(json.error);
        }
    }


    const getPlayers = async (teamID) => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/player?year=${current.year}&team=${teamID}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            var temp = players
            temp = temp.concat(json)
            setPlayers([...temp])
        } else {
            alert(json.error)
        }
    }


    const [npNumber, setNpNumber] = useState('')
    const [npName, setNpName] = useState('')
    const [npSurname, setNpSurname] = useState('')
    const [npPostion, setNpPosition] = useState('')

    const addNewPlayer = async () => {
        if (!npNumber) {
            alert("Zadaj číslo!")
            return
        } else if (players.filter(x => x.team_id === selected.ID).find(y => y.number === parseInt(npNumber))) {
            alert("Toto číslo sa už v tíme nachádza!")
            return
        }


        if (npName < 2 || npSurname < 2) {
            alert("Zadaj meno aj priezvisko!")
            return
        }

        if (npPostion !== 'att' && npPostion !== 'def' && npPostion !== 'gk') {
            alert("Zadaj pozíciu!")
            return
        }


        const response = await fetch(`${process.env.REACT_APP_PATH}/api/player`, {
            method: 'POST',
            body: JSON.stringify({ team_id: selected.ID, role: npPostion, first_name: npName, last_name: npSurname, number: npNumber, year: current.year }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            var temp = players;
            temp.push(json[0])
            setPlayers([...temp])
            setNpNumber('')
            setNpName('')
            setNpSurname('')
            setNpPosition('')
            temp = teams
            temp.find(x => x.ID === selected.ID).player_count++
            setDeleted(false)
            setTeams([...temp])


        } else {
            alert(json.error)
        }



    }


    const deletePlayer = async (e) => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/player/${e.ID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            var temp = players.filter(x => {
                console.log(x.ID);
                console.log(e.ID);
                console.log(x.ID !== e.ID);
                return x.ID !== e.ID
            })
            console.log(temp);
            setPlayers([...temp])

            temp = teams
            temp.find(x => x.ID === selected.ID).player_count--
            setTeams([...temp])

        } else {
            alert(json.error)
        }
    }

    const [editPlayerIndex, setEditPlayerIndex] = useState(-1);

    const [eNumber, setENumber] = useState('')
    const [eName, setEName] = useState('')
    const [eSurname, setESurname] = useState('')
    const [ePostion, setEPosition] = useState('')


    const setEditThisPlayer = (x) => {
        setEditPlayerIndex(x)
        if (x !== -1) {
            setENumber(players.filter(a => a.team_id === selected.ID)[x].number)
            setEName(players.filter(a => a.team_id === selected.ID)[x].first_name)
            setESurname(players.filter(a => a.team_id === selected.ID)[x].last_name)
            setEPosition(players.filter(a => a.team_id === selected.ID)[x].role)
        }

    }

    const savePlayerChange = async (x) => {

        let fff = players[x].team_id
        let ggg = players.filter(a => a.team_id === selected.ID)[x].ID
        if (!eNumber) {
            alert("Zadaj číslo!")
            return
        } else if (players.filter(x => ((x.team_id === fff) && (x.ID !== ggg))).find(y => y.number === parseInt(eNumber))) {
            alert("Toto číslo sa už v tíme nachádza!")
            return
        }


        if (eName < 2 || eSurname < 2) {
            alert("Zadaj meno aj priezvisko!")
            return
        }

        if (ePostion !== 'att' && ePostion !== 'def' && ePostion !== 'gk') {
            alert("Zadaj pozíciu!")
            return
        }
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/player/${players.filter(a => a.team_id === selected.ID)[x].ID}`, {
            method: 'PATCH',
            body: JSON.stringify({ role: ePostion, first_name: eName, last_name: eSurname, number: eNumber }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            let temp = players.filter(a => a.team_id === selected.ID)
            temp[x].number = eNumber
            temp[x].first_name = eName
            temp[x].last_name = eSurname
            temp[x].role = ePostion
            setPlayers([...temp])
            setEditThisPlayer(-1)

        } else {
            alert(json.error)
        }
    }



    return (
        <div className="flex">
            <AdminNavbar />
            <div className="flex-1 p-8">
                <div>
                    <h1 className="text-3xl mb-5">Tímy {current.year}</h1>
                    {(teams) && <div className="w-full table">
                        <div className="table-row">
                            <div className="table-cell border-b pb-2 text-lg pl-2">Meno</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Skupina</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Hráči</div>
                            <div className="table-cell text-center w-20 border-b pb-2 text-lg">Body</div>

                        </div>
                        {teams.map(e => (
                            <div key={e.ID} className={`table-row hover:bg-gray-200 cursor-pointer ${selected.ID === e.ID ? "bg-gray-200" : ""}`} onClick={x => { setSelected(e); setEditThisPlayer(-1) }}>
                                <div className="table-cell border-b pb-2 text-lg pl-2">{e.name}</div>
                                <div className="table-cell text-center w-20 border-b pb-2 text-lg">{e.group_code_name}</div>
                                <div className="table-cell text-center w-20 border-b pb-2 text-lg">{e.player_count}</div>
                                <div className="table-cell text-center w-20 border-b pb-2 text-lg">{e.points}</div>

                            </div>
                        ))}

                    </div>}
                    <form className="flex items-center mt-10 form" onSubmit={createTeam}>
                        <input type="text" placeholder="Názov Tímu" className="p-1 border border-borders mr-1 text-xl" value={newTeamName} onChange={e => setNewTeamName(e.target.value)} />
                        <button className="btn  whitespace-pre text-xl">Pridať tím</button>
                    </form>

                </div>
                {selected &&
                    <div>
                        <div className="mt-10">
                            <h1 className="text-3xl mb-5">{selected.name}</h1>
                        </div>
                        <div className="flex border-b border-t border-borders py-3 mb-3 items-center child:mx-1 justify-between">
                            <form className="flex text-xl justify-between min-w-[10rem]  items-center" onSubmit={saveNewGroup} >
                                <p className="text-xl">Kód:</p>
                                {codes && <select value={newGroup} className="px-5 text-xl py-2 mx-2" onChange={x => setNewGroup(parseInt(x.target.value))}>
                                    {codes.map(e => (
                                        <option key={e.ID} value={e.ID} className="px-5">{e.name}</option>
                                    ))}
                                </select>}
                                {groups && <button disabled={newGroup === selected.group_code || newGroup === 0} className="btn">Zmeniť</button>}
                            </form>
                            <form className="flex items-center" onSubmit={changeTeamName}>
                                <p className="text-xl mr-3">Zmeniť meno: </p>
                                <input type="text" placeholder="Nové meno tímu" className="p-1 border border-borders mr-1 text-xl max-w-[140px]" value={editedTeamName} onChange={e => setEditedTeamName(e.target.value)} />
                                <button disabled={selected.name === editedTeamName} className="btn  whitespace-pre text-xl">Zmeniť</button>
                            </form>

                            <form className="flex items-center" onSubmit={deleteThisTeam}>
                                <p className="text-xl mr-3">Odstrániť tento tím: </p>
                                <input type="text" placeholder="Potvrď meno" className="p-1 border border-borders mr-1 text-xl max-w-[140px]" value={deleteTeam} onChange={e => setDeleteTeam(e.target.value)} />
                                <button disabled={selected.name !== deleteTeam} className="btn  whitespace-pre text-xl">Odstrániť</button>
                            </form>
                        </div>
                        <div className="mt-10">
                            <h1 className="text-2xl mb-5">Hráči</h1>
                            {(teams) && <div className="w-full table ">
                                <div className="table-row">
                                    <div className="table-cell border-b pb-2 text-lg pl-2 w-2"></div>
                                    <div className="table-cell border-b pb-2 text-lg pl-2 w-5">Číslo</div>
                                    <div className="table-cell border-b pb-2 text-lg pl-2">Meno</div>
                                    <div className="table-cell text-center w-20 border-b pb-2 text-lg">Pozícia</div>
                                    <div className="table-cell text-center w-20 border-b pb-2 text-lg">Upráva</div>
                                    <div className="table-cell text-center w-20 border-b pb-2 text-lg">Odstránenie</div>

                                </div>
                                {players.filter(x => x.team_id === selected.ID).map((e, i) => (
                                    <div key={e.ID} className={`table-row-group hover:bg-gray-200 cursor-pointer ${selected.ID === e.ID ? "bg-gray-200" : ""}`} >
                                        {i !== editPlayerIndex && <div className="table-row">
                                            <div className="table-cell border-b py-1 text-lg pl-2">{i + 1}.</div>
                                            <div className="table-cell border-b py-1 text-lg pl-2">{e.number}</div>
                                            <div className="table-cell border-b py-1 text-lg pl-2">{e.first_name} {e.last_name}</div>
                                            <div className="table-cell text-center w-20 border-b  text-lg">
                                                {e.role === 'att' && <span>Útočník</span>}
                                                {e.role === 'def' && <span>Obranca</span>}
                                                {e.role === 'gk' && <span>Brankár</span>}
                                            </div>
                                            <div className="table-cell text-center w-20 border-b  text-lg mr-3"><div className="text-accent underline font-bold  " onClick={x => setEditThisPlayer(i)}>Upraviť</div></div>
                                            <div className="table-cell text-center w-20 border-b  text-lg"><div className="text-red-500 underline font-bold" onClick={x => deletePlayer(e)}>Odstrániť</div></div>
                                        </div>}
                                        {i === editPlayerIndex && <div className="table-row">
                                            <div className="table-cell border-b py-1 text-lg pl-2">{i + 1}</div>
                                            <div className="table-cell border-b py-1 text-lg pl-2"><input type="number" placeholder="číslo" className="p-1" defaultValue={undefined} value={eNumber} onChange={e => setENumber(e.target.value)} /></div>
                                            <div className="table-cell border-b py-1 text-lg pl-2">
                                                <input type="text" placeholder="Meno" className="mr-2 p-1" value={eName} onChange={e => setEName(e.target.value)} />
                                                <input type="text" className="mr-2 p-1" placeholder="Priezvisko" value={eSurname} onChange={e => setESurname(e.target.value)} />
                                            </div>
                                            <div className="table-cell text-center w-20 border-b  text-lg">
                                                <select name="" id="" className="p-2" value={ePostion} onChange={e => setEPosition(e.target.value)}>
                                                    <option value={''} className="p-1"></option>
                                                    <option value="att" className="p-1">Útočník</option>
                                                    <option value="def" className="p-1">Obranca</option>
                                                    <option value="gk" className="p-1">Brankár</option>
                                                </select>
                                            </div>
                                            <div className="table-cell text-center w-20 border-b  text-lg mr-3"><div className="text-accent underline font-bold  " onClick={x => setEditThisPlayer(-1)}>Zrušiť</div></div>
                                            <div className="table-cell text-center w-20 border-b  text-lg"><div className="btn" onClick={q => savePlayerChange(i)}>Uložiť</div></div>
                                        </div>}


                                    </div>
                                ))}
                                <div className={`table-row pt-5`} >
                                    <div className="table-cell border-b py-1 text-lg pl-2 pt-10 pb-5">Nový:</div>
                                    <div className="table-cell border-b py-1 text-lg pl-2"><input type="number" placeholder="číslo" className="p-1" defaultValue={undefined} value={npNumber} onChange={e => setNpNumber(e.target.value)} /></div>
                                    <div className="table-cell border-b py-1 text-lg pl-2">
                                        <input type="text" placeholder="Meno" className="mr-2 p-1" value={npName} onChange={e => setNpName(e.target.value)} />
                                        <input type="text" className="mr-2 p-1" placeholder="Priezvisko" value={npSurname} onChange={e => setNpSurname(e.target.value)} />
                                    </div>
                                    <div className="table-cell text-center w-20 border-b  text-lg">
                                        <select name="" id="" className="p-2" value={npPostion} onChange={e => setNpPosition(e.target.value)}>
                                            <option value={''} className="p-1"></option>
                                            <option value="att" className="p-1">Útočník</option>
                                            <option value="def" className="p-1">Obranca</option>
                                            <option value="gk" className="p-1">Brankár</option>
                                        </select>
                                    </div>
                                    <div className="table-cell text-center w-20 border-b  text-lg"></div>
                                    <div className="table-cell text-center w-20 border-b  text-lg"><div className="btn" onClick={addNewPlayer}>Pridať</div></div>

                                </div>

                            </div>}
                        </div>
                    </div>
                }

            </div>
        </div >
    );
}

export default Teams;