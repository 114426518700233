import { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useYearContext } from "../../hooks/useYearContext"
import { format } from "date-fns";
import { sk } from "date-fns/locale";

const Tournament = () => {
    const [years, setYears] = useState()

    const { user } = useAuthContext();
    const { current } = useYearContext()
    const [open, setOpen] = useState(false);
    const { dispatch } = useYearContext()

    const [newYear, setNewYear] = useState('')
    const [newYearStart, setNewYearStart] = useState(0)
    const [newYearEnd, setNewYearEnd] = useState(0)

    const [newStatus, setNewStatus] = useState(current.status);
    const [currentYear, setCurrentYear] = useState(current.year)
    const [deleteYear, setDeleteYear] = useState(0)

    const [groups, setGroups] = useState([])
    const [newGroup, setNewGroup] = useState('');
    const [newGroupCount, setNewGroupCount] = useState(0)
    const [fields, setFields] = useState([])

    const [patchField, setPatchField] = useState('')
    const [newFieldName, setNewFieldName] = useState()

    const [days, setDays] = useState([])

    const [events, setEvents] = useState([])
    const [neTime, setNeTime] = useState([])
    const [neText, setNeText] = useState([])



    const fetchYears = async () => {

        const response = await fetch(`${process.env.REACT_APP_PATH}/api/year`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (response.ok) {
            setYears(json)

        }



    }

    const openAllYears = (operation) => {
        if (operation) {
            if (!years) {
                fetchYears()
            }

            setOpen(true)
        } else {
            setOpen(false)
        }

    }

    const changeStatus = async () => {
        const changes = { status: newStatus }
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/year/` + current.year, {
            method: 'POST',
            body: JSON.stringify(changes),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        if (response.ok) {
            let temp_current = current;
            temp_current.status = newStatus;
            dispatch({ type: 'SET', payload: temp_current })
            let temp = years.filter(e => e.year !== temp_current.year)
            temp.push(temp_current);
            setYears([...temp])
        }
    }

    const addYear = async () => {
        if (newYearStart === 0 || newYearEnd === 0) {
            alert("Zadaj začiatok a koniec turnaja!")
            return
        }
        console.log(newYearStart, newYearEnd);
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/year/`, {
            method: 'POST',
            body: JSON.stringify({ year: newYear, start: newYearStart, end: newYearEnd }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            let temp = years;
            temp.push(json[0])
            setYears([...temp])
            setNewYear('')
            setNewYearStart(0)
            setNewYearEnd(0)
        } else {
            if (json.error.errno === 1062) {
                alert("Tento ročník už existuje!")
            }
        }


    }

    const selectCurrent = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/year/current`, {
            method: 'POST',
            body: JSON.stringify({ year: currentYear }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            dispatch({ type: 'SET', payload: json[0] })
        } else {
            console.log(json.error);
        }


    }

    const deleteThisYear = async () => {
        console.log(deleteYear);
        console.log(groups);
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/year/` + deleteYear, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            let temp = years.filter(e => e.year !== deleteYear)
            setDeleteYear(0)
            setYears([...temp])

        } else {
            alert(json.error)
        }
    }


    useEffect(() => {
        const getGroups = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/group?year=${current.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setGroups(json)
            }
        }

        const getFields = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/field`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setFields(json)
            }
        }

        const getEvents = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/year/event?year=${current.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setEvents(json)
            }
        }
        if (current) {
            getGroups()
            getFields()
            getEvents()

            let temp = []
            for (let i = 0; i < datediff(new Date(current.start), new Date(current.end)); i++) {
                let date = new Date(current.start)
                date.setDate(date.getDate() + i)
                temp.push(date)
            }

            function datediff(first, second) {
                return Math.round((second - first) / (1000 * 60 * 60 * 24)) + 1;
            }
            let x = [];
            let y = []
            for (let i = 0; i < temp.length; i++) {
                x.push(0)
                y.push('')

            }
            setNeText(y)
            setNeTime(x)
            setDays(temp)
        }




    }, [current])

    const addGroup = async (e) => {
        e.preventDefault()
        if (newGroup.length < 0) {
            alert("Zadaj názov skupiny!")
            return
        }
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/group/`, {
            method: 'POST',
            body: JSON.stringify({ name: newGroup, year: current.year, count: newGroupCount }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            let temp = groups;
            temp.push(json[0])
            setGroups([...temp])
            setNewGroup('')
            setNewGroupCount(0)
        } else {
            alert(json.error)
            setNewGroup('')
        }
    }



    const removeGroup = async (e) => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/group/` + e.ID, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            let temp = groups.filter(f => e.ID !== f.ID)
            console.log(temp);
            setGroups([...temp])

        } else {
            alert(json.error)
        }
    }

    const updateField = async (e) => {
        e.preventDefault()
        if (newFieldName.length < 1) {
            alert("Zadaj názov Ihriska!")
            return
        }
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/field/` + patchField, {
            method: 'PATCH',
            body: JSON.stringify({ name: newFieldName }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })
        const json = await response.json()
        if (response.ok) {
            let temp = fields;
            temp.find(e => e.ID === patchField).field_name = newFieldName;
            setFields([...temp])
            setPatchField('')
        } else {
            alert(json.error)
        }
    }

    const addEvent = async (e, i) => {
        e.preventDefault()
        if (neText[i] === '') {
            alert("Zadaj názov programu!")
            return
        }

        if (neTime[i] === 0) {
            alert("Zadaj čas programu!")
            return
        }
        console.log(neTime[i]);
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/year/event/`, {
            method: 'POST',
            body: JSON.stringify({ event: neText[i], year: current.year, date_time: format(days[i], `yyyy-MM-dd`) + 'T' + neTime[i] }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            let temp = [...events];
            temp.push(json[0])
            setEvents(temp)
        } else {
            alert(json.error)
        }
    }

    const removeEvent = async (e) => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/year/event/` + e.ID, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            let temp = events.filter(f => e.ID !== f.ID)
            setEvents([...temp])

        } else {
            alert(json.error)
        }
    }


    return (
        <div className="flex">
            <AdminNavbar />
            <div className="flex-1 p-8">
                <div className="bg-white rounded px-8 py-5 mb-8 ">
                    <div className="flex justify-between items-center  cursor-pointer" onClick={e => openAllYears(!open)}>
                        <div></div>
                        <h1 className="text-2xl ">Aktuálny ročník: {current.year} <span className="text-lg text-dark-text">({current.status})</span> </h1>
                        <div>
                            <img src="/img/arrow.svg" alt="" />
                        </div>
                    </div>

                    {(open && years) &&
                        <div className="mt-8 flex">

                            <div className="flex flex-col items-center justify-between w-1/3 ">
                                <h2 className="text-lg ">Pridať nový ročník</h2>
                                <div className="text-center flex flex-col">
                                    <input type="text" className=" text-black border border-black p-1" placeholder="Rok (2023,2024....)" value={newYear} onChange={e => setNewYear(e.target.value)} />
                                    <label htmlFor="">Začiatok</label>
                                    <input type="date" className=" text-black border border-black p-1" placeholder="Rok (2023,2024....)" value={newYearStart} onChange={e => setNewYearStart(e.target.value)} />
                                    <label htmlFor="">Koniec</label>
                                    <input type="date" className=" text-black border border-black p-1" placeholder="Rok (2023,2024....)" value={newYearEnd} onChange={e => setNewYearEnd(e.target.value)} />
                                </div>

                                <button disabled={newYear.length < 4} className={"btn"} onClick={addYear}>Pridať</button>
                                {/* <div className="text-dark-text text-center">Toto nastavenie mení zobrazenie domovskej stránky. Planned ukáže správu "Tešíme sa na Vás","ongoing" ukáže prebiehajúce zápasy a "finished" ukáže víťaza</div> */}
                            </div>
                            <div className="flex flex-col items-center justify-between w-1/3 ">
                                <h2 className="text-lg ">Nastaviť aktuálny ročník</h2>
                                <select className="text-xl p-1" defaultValue={current.year} onChange={e => setCurrentYear(parseInt(e.target.value))}>
                                    {years.map(e => (
                                        <option key={e.year} value={e.year}>{e.year}</option>
                                    ))}
                                </select>

                                <button disabled={current.year === currentYear} className={"btn"} onClick={selectCurrent}>Potvrdiť</button>
                                {/* <div className="text-dark-text text-center">Toto nastavenie mení zobrazenie domovskej stránky. Planned ukáže správu "Tešíme sa na Vás","ongoing" ukáže prebiehajúce zápasy a "finished" ukáže víťaza</div> */}
                            </div>
                            <div className="flex flex-col items-center justify-between w-1/3 h-[20vh]">
                                <h2 className="text-lg ">Status aktuálneho ročníka</h2>
                                <div className="flex items-center">
                                    <select className="text-xl p-1" defaultValue={current.status} onChange={e => setNewStatus(e.target.value)}>
                                        <option value="planned">planned</option>
                                        <option value="ongoing">ongoing</option>
                                        <option value="finished">finished</option>
                                    </select>
                                </div>

                                <button disabled={current.status === newStatus} className={"btn"} onClick={changeStatus}>Uložiť</button>
                            </div>
                            <div className="flex flex-col items-center justify-between w-1/3 ">
                                <h2 className="text-lg ">Odstrániť ročník</h2>
                                <select className="text-xl p-1" defaultValue={0} onChange={e => setDeleteYear(parseInt(e.target.value))}>
                                    <option value={0}></option>
                                    {years.filter(e => e.status === 'planned' && e.year !== current.year).map(e => (
                                        <option key={e.year} value={e.year}>{e.year}</option>
                                    ))}
                                </select>

                                <button disabled={deleteYear === 0} className={"btn"} onClick={deleteThisYear}>Odstrániť</button>
                            </div>

                        </div>
                    }
                </div>
                <div className=" rounded px-8 py-5 ">
                    <h1 className="text-2xl font-bold mb-5">{current.year}</h1>
                    <div className="flex">

                        <div className="text-lg mr-10">
                            <h2>Skupiny</h2>
                            <div className="flex flex-col items-center child:mr-1 ">

                                <form onSubmit={addGroup}>
                                    <input type="text" className=" text-black border border-borders  p-1" placeholder="Názov skupiny(A,B,...)" value={newGroup} onChange={e => setNewGroup(e.target.value)} />
                                    <label>Tímy v skupine:</label>
                                    <input type="number" className="w-16 p-1" value={newGroupCount} onChange={e => setNewGroupCount(e.target.value)} />
                                    <button disabled={newGroup.length < 1 || newGroupCount < 1} className="btn">Pridať</button>
                                </form>
                                <div className="flex flex-wrap child:mr-2  ">
                                    {groups.length === 0 && <p className="text-dark-text">Nenašli sa žiadne skupiny...</p>}
                                    {groups.filter(e => e.group_name !== '-').map(e => (
                                        <div className="bg-white my-2 rounded p-1 w-52 flex justify-between" key={e.ID}>
                                            <p>Skupina {e.group_name}</p>
                                            <p>{e.count} x tím</p>
                                            <p className="text-red-600 cursor-pointer font-bold" onClick={f => removeGroup(e)}>X</p>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                        <div className="text-lg ">
                            <h2>Ihriská</h2>
                            <div className="flex items-center child:mr-1 ">
                                <div className="flex child:mr-2 select-none ">
                                    {fields.length === 0 && <p className="text-dark-text">Nenašli sa žiadne ihriská...</p>}
                                    {fields.filter(e => e.field_name !== '-').map(e => (

                                        <div className="bg-white my-2  rounded min-w-[13rem]" key={e.ID}>
                                            {patchField !== e.ID &&
                                                <div className="flex justify-between items-center p-1">
                                                    <p>Ihrisko {e.field_name}</p>
                                                    <p className="text-accent cursor-pointer" onClick={f => { setPatchField(e.ID); setNewFieldName(e.field_name) }}>Edit</p>
                                                </div>
                                            }
                                            {patchField === e.ID &&
                                                <form className="flex justify-between items-center bg-white pl-3" onSubmit={updateField}>
                                                    <input type="text" value={newFieldName} onChange={x => setNewFieldName(x.target.value)} />
                                                    <p className="text-accent cursor-pointer mx-3" onClick={f => setPatchField('')}>Cancel</p>
                                                    <button className="btn ">Uložiť</button>

                                                </form>
                                            }

                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="w-full h-[2px] bg-borders"></div>
                    <div className="text-xl mt-3 ">
                        <div className="text-xl ">
                            <h2>Program</h2>
                            {days.map((e, i) => (

                                <div className="my-5">
                                    <div className="flex items-center mb-5">
                                        <h1 className="bold text-lg mr-5">{format(days[i], 'eeee(d.M)', { locale: sk }).charAt(0).toUpperCase() + format(days[i], "eeee (d.M)", { locale: sk }).slice(1)}</h1>
                                        <form className="flex items-center child:mx-1" onSubmit={x => addEvent(x, i)}>
                                            <p>Čas:</p>
                                            <input type="time" value={neTime[i]} onChange={e => { let temp = [...neTime]; temp[i] = e.target.value; setNeTime(temp); console.log(temp); }} className="border border-borders" />
                                            <p>Text:</p>
                                            <input type="text" value={neText[i]} onChange={e => { let temp = [...neText]; temp[i] = e.target.value; setNeText(temp) }} className="border border-borders" />
                                            <button className="btn">Pridať</button>
                                        </form>
                                    </div>

                                    <div className="table">
                                        <div className="table-row">
                                            <div className="table-cell border-b border-borders w-20">Čas</div>
                                            <div className="table-cell border-b border-borders ">Program</div>
                                            <div className="table-cell border-b border-borders px-2">Vymazať</div>
                                        </div>
                                        {events.filter(x => format(new Date(x.date_time), "d.M", { locale: sk }) === format(days[i], "d.M")).map((f, j) => (
                                            <div className="table-row">
                                                <div className="table-cell pb-1">
                                                    {f.date_time.substring(f.date_time.indexOf("T") + 1, f.date_time.indexOf("T") + 1 + 5)}
                                                </div>
                                                <div className="table-cell">
                                                    {f.event}
                                                </div>
                                                <div className="text-xl text-red-700 cursor-pointer text-center" onClick={e => removeEvent(f)}>
                                                    X
                                                </div>
                                            </div>
                                        ))}
                                    </div>


                                </div>

                            ))}


                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Tournament;