import { createContext, useReducer, useEffect } from "react";

export const YearContext = createContext();

export const yearReducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            return { current: action.payload }
        default:
            return state;
    }
}


export const YearContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(yearReducer, {
        current: undefined
    })

    useEffect(() => {



        const fetchYear = async () => {
            const res = await fetch(`${process.env.REACT_APP_PATH}/api/year/current`)

            const json = await res.json()

            if (res.ok) {
                dispatch({ type: 'SET', payload: json[0] })
            }
        }


        fetchYear()


    }, [])

    return (
        <YearContext.Provider value={{ ...state, dispatch }}>
            {children}
        </YearContext.Provider>
    )
}