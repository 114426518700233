import { useEffect, useState } from "react";
import { useYearContext } from "../../hooks/useYearContext";
import Loader from "../../components/Loader";


const UserStats = () => {
    const { current } = useYearContext()
    const [players, setPlayers] = useState([])
    const [keepers, setKeepers] = useState([])

    const [playersFinished, setPlayersFinished] = useState(false)

    const [showPlayers, setShowPlayers] = useState(8)
    const [showKeepers, setShowKeepers] = useState(8)



    useEffect(() => {
        if (current) {
            const getPlayers = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/player/stats?year=${current.year}`)
                let json = await response.json()
                if (response.ok) {

                    json = json.filter(e => {

                        if (e.penalties == null) {
                            e.penalties = 0
                        }


                        return e.penalties + e.goals + e.assists > 0
                    })

                    setPlayers(json.sort((a, b) => (b.goals + b.assists) - (a.goals + a.assists) || b.goals - a.goals || a.penalties - b.penalties))
                    setPlayersFinished(true)
                    console.log(playersFinished);
                } else {
                    alert(json.error)
                }
            }

            const getKeepers = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/player/keeper-stats?year=${current.year}`)
                let json = await response.json()
                if (response.ok) {

                    json = json.filter(e => {
                        return e.interventions + e.recieved_goals > 0
                    })

                    setKeepers(json.sort((a, b) => (100 - (b.recieved_goals / (b.recieved_goals + b.interventions) * 100)) - (100 - (a.recieved_goals / (a.recieved_goals + a.interventions) * 100)) || a.recieved_goals - b.recieved_goals || a.interventions - b.interventions))
                } else {
                    alert(json.error)
                }
            }

            getKeepers()
            getPlayers()
        }


    }, [current])

    return (
        <div className=" w-[90vw] md:w-[70vw] xl:w-[46vw] mx-auto">
            {(players.length > 0 && keepers.length > 0) && <div>
                <div className="my-12  ">
                    <div className="bg-white p-8 border border-borders">
                        <h2 className="text-xl font-semibold mb-3">Hráči</h2>
                        <div className="table w-full ">
                            <div className="table-row  child:border-borders text-dark-text child:text-sm">
                                <div className="table-cell w-[7%]"></div>
                                <div className="table-cell">Meno</div>
                                <div className="table-cell">Tím</div>
                                <div className="table-cell text-center w-10">G</div>
                                <div className="table-cell text-center w-10">A</div>
                                <div className="table-cell text-center w-10">B</div>
                                <div className="table-cell text-center">T</div>
                            </div>
                            {players.map((f, i) => (
                                <div className="table-row-group">
                                    {i < showPlayers && <div key={f.ID} className="table-row child:border-t child:border-borders  child:py-3">
                                        <div className="table-cell w-[7%] text-center pr-1">{i + 1}</div>
                                        <div className="table-cell w-1/4 pr-2 whitespace-pre  "><span className="md:hidden">{f.first_name.charAt(0)}.</span><span className="hidden md:inline">{f.first_name}</span> {f.last_name}</div>
                                        <div className="table-cell md:w-1/3 text-dark-text">{f.team_name}</div>
                                        <div className="table-cell text-center w-10">{f.goals}</div>
                                        <div className="table-cell text-center w-10">{f.assists}</div>
                                        <div className="table-cell text-center w-10">{f.goals + f.assists}</div>
                                        <div className="table-cell w-10 text-center whitespace-pre">{f.penalties}'</div>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                    {(showPlayers < 9 && players.length > 8) && <div className="ml-auto mr-5 bg-borders px-2 leading-7 w-min cursor-pointer select-none   text-white text-3xl rounded-b-md" onClick={x => setShowPlayers(1000)}>+</div>}
                    {showPlayers > 8 && <div className="ml-auto mr-5 bg-borders px-2 leading-7 w-min cursor-pointer select-none   text-white text-3xl rounded-b-md" onClick={x => setShowPlayers(8)}>-</div>}
                </div>
                <div className="my-12 ">
                    <div className="bg-white p-8 border border-borders">
                        <h2 className="text-xl font-semibold mb-3">Brankári</h2>
                        <div className="table w-full ">
                            <div className="table-row  child:border-borders text-dark-text child:text-sm">
                                <div className="table-cell w-[7%]"></div>
                                <div className="table-cell">Meno</div>
                                <div className="table-cell">Tím</div>
                                <div className="table-cell md:w-10 text-center">Z</div>
                                <div className="table-cell md:w-10 text-center">IG</div>
                                <div className="table-cell md:w-10 text-center px-0">%</div>
                            </div>
                            {keepers.map((f, i) => (
                                <div className="table-row-group">
                                    {i < 8 && <div key={f.ID} className="table-row child:border-t child:border-borders  child:py-3">
                                        <div className="table-cell w-[7%] text-center">{i + 1}</div>
                                        <div className="table-cell w-1/4 pr-2 whitespace-pre"><span className="md:hidden">{f.first_name.charAt(0)}.</span><span className="hidden md:inline">{f.first_name}</span> <span> {f.last_name}</span></div>
                                        <div className="table-cell md:w-1/3 text-dark-text">{f.team_name}</div>
                                        <div className="table-cell md:w-10 text-center">{f.interventions}</div>
                                        <div className="table-cell md:w-10 text-center">{f.recieved_goals}</div>
                                        <div className="table-cell md:w-10 text-center px-0"> <span className="md:hidden"> {Math.round((100 - (f.recieved_goals / (f.recieved_goals + f.interventions) * 100)) * 10) / 10}</span> <span className="hidden md:block"> {Math.round((100 - (f.recieved_goals / (f.recieved_goals + f.interventions) * 100)) * 100) / 100}</span> </div>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                    {(showKeepers < 9 && keepers.length > 8) && <div className="ml-auto mr-5 bg-borders px-2 leading-7 w-min cursor-pointer select-none   text-white text-3xl rounded-b-md" onClick={x => setShowKeepers(1000)}>+</div>}
                    {showKeepers > 8 && <div className="ml-auto mr-5 bg-borders px-2 leading-7 w-min cursor-pointer select-none   text-white text-3xl rounded-b-md" onClick={x => setShowKeepers(8)}>-</div>}
                </div>
            </div>}
            {(!(players.length > 0 || keepers.length > 0) && !playersFinished) && <Loader />}
            {(!(players.length > 0 || keepers.length > 0) && playersFinished) && <div className="text-center mt-12"> Zatiaľ žiadni hráči :( </div>}
        </div>
    );
}

export default UserStats;