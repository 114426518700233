import { useEffect, useState } from "react";
import { useYearContext } from "../../hooks/useYearContext";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";

const UserLeaderboard = () => {
    const { current } = useYearContext()
    const [games, setGames] = useState([])
    const [teams, setTeams] = useState([])
    const [groups, setGroups] = useState([])
    const [codes, setCodes] = useState([])

    useEffect(() => {
        if (current) {
            let tempGames;
            const getGames = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/finished?year=${current.year}`)
                const json = await response.json()
                if (response.ok) {
                    setGames(json)
                    tempGames = json
                    getTeams()
                } else {
                    alert(json.error)
                }
            }
            const getGroups = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/group?year=${current.year}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    setGroups(json)
                } else {
                    alert(json.error)
                }
            }

            const getGroupCodes = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/group/codes?year=${current.year}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    console.log(json);
                    setCodes(json)
                } else {
                    alert(json.error)
                }
            }
            const getTeams = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams?year=${current.year}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    calculateTeams(json, tempGames)
                } else {
                    alert(json.error)
                }
            }

            getGroupCodes()
            getGroups()
            getGames()
        }


    }, [current])

    const calculateTeams = (teams, games) => {
        teams.forEach(e => {
            e.z = 0
            e.v = 0
            e.p = 0
            e.r = 0
            e.g = 0
            e.ig = 0
            e.b = 0
            e.WinAgainst = []
        });
        games.filter(x => x.text === "group").forEach(e => {
            let ta = teams.find(x => x.ID === e.team_a_id)
            let tb = teams.find(x => x.ID === e.team_b_id)

            ta.z++;
            tb.z++;

            if (e.winner === ta.ID) {
                ta.v++
                tb.p++
                ta.b += 3
                ta.WinAgainst.push(tb.ID)
            }
            if (e.winner === tb.ID) {
                tb.v++
                ta.p++
                tb.b += 3
                tb.WinAgainst.push(ta.ID)
            }
            if (e.winner === 0 && e.tie === 1) {
                ta.r++
                tb.r++
                ta.b += 1
                tb.b += 1
            }

            ta.g += e.team_a_goals;
            ta.ig += e.team_b_goals

            tb.g += e.team_b_goals;
            tb.ig += e.team_a_goals
        })


        setTeams(teams.sort((a, b) => b.b - a.b || b.WinAgainst.includes(a.ID) - a.WinAgainst.includes(b.ID) || (b.g - b.ig) - (a.g - a.ig) || b.g - a.g))

    }



    return (
        <div className="  w-[90vw] md:w-[70vw] xl:w-[46vw] mx-auto">
            <h1 className=" md:hidden text-accent text-center text-6xl  mt-12">Priebeh</h1>
            {(teams.length > 0 && codes.length > 0) && groups.map(e => (
                <div key={e.ID}>

                    {teams.filter(x => codes.find(z => z.ID === x.group_code).name.includes(e.group_name)).length > 0 &&
                        <div className="my-12 bg-white p-8 border border-borders">
                            <h2 className="text-xl font-semibold mb-3">Skupina {e.group_name}</h2>
                            <div className="table w-full ">
                                <div className="table-row  child:border-borders text-dark-text child:text-sm">
                                    <div className="hidden md:table-cell w-10"></div>
                                    <div className="table-cell">Tím</div>
                                    <div className="table-cell pr-3 md:px-3 w-24">Z</div>
                                    <div className="table-cell pr-3 md:px-3 w-24">V</div>
                                    <div className="table-cell pr-3 md:px-3 w-24">P</div>
                                    <div className="table-cell pr-3 md:px-3 w-24">R</div>
                                    <div className="hidden md:table-cell pr-3 md:px-3 w-24">G</div>
                                    <div className="hidden md:table-cell pr-3 md:px-3 w-24">IG</div>
                                    <div className="table-cell pr-3 md:px-3 w-24">+/-</div>
                                    <div className="table-cell pr-3 md:px-3 w-24">B</div>
                                </div>
                                {teams.filter(x => codes.find(z => z.ID === x.group_code).name.includes(e.group_name)).map((f, i) => (
                                    <Link to={`/teams/${f.ID}`} key={f.ID} className="table-row child:border-t child:border-borders  child:py-3">
                                        <div className="hidden md:table-cell w-[7%] text-center">{i + 1}</div>
                                        <div className="table-cell w-2/3">{f.name}</div>
                                        <div className="table-cell pr-3 md:px-3 w-24">{f.z}</div>
                                        <div className="table-cell pr-3 md:px-3 w-24">{f.v}</div>
                                        <div className="table-cell pr-3 md:px-3 w-24">{f.p}</div>
                                        <div className="table-cell pr-3 md:px-3 w-24">{f.r}</div>
                                        <div className="hidden md:table-cell pr-3 md:px-3 w-24">{f.g}</div>
                                        <div className="hidden md:table-cell pr-3 md:px-3 w-24">{f.ig}</div>
                                        <div className="table-cell pr-3 md:px-3 w-24">{f.g - f.ig}</div>
                                        <div className="table-cell pr-3 md:px-3 w-24">{f.b}</div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    }
                </div>

            ))}
            {!(groups.length > 0 && teams.length > 0) && <Loader />}
        </div>
    );
}

export default UserLeaderboard;