import { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import { useAuthContext } from "../../hooks/useAuthContext";

const Gallery = () => {

    const { user } = useAuthContext();

    const [gallery, setGallery] = useState([]);
    const [years, setYears] = useState([]);

    const [newText, setNewText] = useState('')
    const [newText2, setNewText2] = useState('')
    const [newLink, setNewLink] = useState('')
    const [newYear, setNewYear] = useState('')

    useEffect(() => {

        const getGallery = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/gallery`)
            const json = await response.json()

            if (response.ok) {
                setGallery(json)
            }
        }

        const getYears = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/year`)
            const json = await response.json()

            if (response.ok) {
                setYears(json)
            }
        }

        getGallery();
        getYears();






    }, [])

    const newGallery = async (e) => {
        e.preventDefault()
        if (newYear === '') {
            alert("Zadaj ročník!")
            return
        }

        console.log({ heading: newText, year: newYear, link: newLink });
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/gallery/`, {
            method: 'POST',
            body: JSON.stringify({ heading: newText, subheading: newText2, year: newYear, link: newLink }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            setNewYear('')
            setNewText2('')
            setNewLink('')
            setNewText('')

            let temp = gallery
            temp.push(json[0])
            setGallery([...temp])
        } else {
            alert(json.error)
        }
    }

    const deleteGallery = async (e, i) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/gallery/${gallery[i].ID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {

            let temp = gallery.filter(x => x.ID !== gallery[i].ID)
            setGallery([...temp])
        } else {
            alert(json.error)
        }
    }


    return (
        <div className="flex">
            <AdminNavbar />
            <div className="flex-1 p-8">
                <h1 className="text-3xl mb-5">Galéria</h1>
                <div className="w-2/3 table mb-5">
                    <div className="table-row">
                        <div className="table-cell border-b pb-2 text-xl">Nadpis</div>
                        <div className="table-cell border-b pb-2 text-xl">Podnadpis</div>
                        <div className="table-cell border-b pb-2 text-xl">Link</div>
                        <div className="table-cell text-center border-b pb-2 text-xl">Ročík</div>
                        {/* <div className="table-cell text-center w-24 border-b pb-2 text-xl">Upráva</div> */}
                        <div className="table-cell text-center w-24 border-b pb-2 text-xl">Odstránenie</div>

                    </div>
                    {gallery.map((e, i) => (
                        <div key={e.ID} className="child:mr-10 table-row">
                            <p className="table-cell text-xl pb-2  border-b py-3">{e.heading}</p>
                            <p className="table-cell text-xl pb-2  border-b py-3">{e.subheading}</p>
                            <a className="table-cell underline text-accent italic text-xl pb-2  border-b " target="_blank" href={e.link} >Link</a>
                            <p className="table-cell text-center text-xl  border-b  pb-2">{e.year}</p>
                            {/* <div className="table-cell text-center w-20 border-b  text-lg mr-3"><div className="text-accent underline font-bold  " >Upraviť</div></div> */}
                            <div className="table-cell text-center w-20 border-b  text-lg"><div className="text-red-500 underline font-bold cursor-pointer" onClick={x => deleteGallery(x, i)} >Odstrániť</div></div>
                        </div>
                    ))}
                </div>
                <form onSubmit={newGallery}>
                    <input className="p-1 py-2 text-xl mr-5" type="text" value={newText} placeholder="Nadpis" onChange={e => setNewText(e.target.value)} />
                    <input className="p-1 py-2 text-xl mr-5" type="text" value={newText2} placeholder="Podnadpis" onChange={e => setNewText2(e.target.value)} />
                    <input className="p-1 py-2 text-xl mx-5 w-72" type="text" value={newLink} placeholder="Link (https://photos.google...." onChange={e => setNewLink(e.target.value)} />
                    <select className="p-1 py-2 text-xl mx-5" value={newYear} onChange={e => setNewYear(e.target.value)}>
                        <option value=""></option>
                        {years.map(e => (
                            <option key={e.year} value={e.year}>{e.year}</option>
                        ))}
                    </select>
                    <button className="btn text-lg">Pridať</button>
                </form>
            </div>
        </div >
    );
}

export default Gallery;