import React from 'react';
import ReactDOM from 'react-dom/client';
import './output.css';
import App from './App';

import { AuthContextProvider } from './context/AuthContext'
import { YearContextProvider } from './context/YearContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <YearContextProvider>
        <App />
      </YearContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

