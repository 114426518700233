import { useEffect, useState } from "react";
import SmallGame from "../../components/SmallGame";
import { useParams } from "react-router-dom";
import { useYearContext } from "../../hooks/useYearContext";

const UserTeam = () => {
    const { id } = useParams()
    const { current } = useYearContext();

    const [team, setTeam] = useState()
    const [players, setPlayers] = useState([])
    const [keepers, setKeepers] = useState([])
    const [games, setGames] = useState([])

    //get all match data
    useEffect(() => {
        if (current) {

            const getAll = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/teams/page/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    setGames([...json.games.filter(x => x.status === "live"), ...json.games.filter(x => x.status !== "live").sort((a, b) => a.status.localeCompare(b.status))])
                    setTeam(json.team[0])
                    console.log(json.games);
                    let temp_p = json.players.filter(e => {

                        if (e.penalties == null) {
                            e.penalties = 0
                        }


                        return e.penalties + e.goals + e.assists > 0 || e.role !== 'gk'
                    })
                    let temp_k = json.players.filter(e => {
                        return e.interventions + e.recieved_goals > 0 || e.role === 'gk'
                    })

                    setKeepers(temp_k.sort((a, b) => (100 - (b.recieved_goals / b.interventions * 100)) - (100 - (a.recieved_goals / a.interventions * 100)) || a.recieved_goals - b.recieved_goals || a.interventions - b.interventions))
                    setPlayers(temp_p.sort((a, b) => (b.goals + b.assists) - (a.goals + a.assists) || b.goals - a.goals || a.penalties - b.penalties))
                    console.log(temp_p.sort((a, b) => (b.goals + b.assists) - (a.goals + a.assists) || b.goals - a.goals || a.penalties - b.penalties));
                } else {
                    alert(json.error)
                }
            }
            getAll()


        }

    }, [current, id])

    useEffect(() => {



    }, [current])





    return (
        <div className=" w-[85vw] md:w-[70vw] xl:w-[46vw] mx-auto mt-12">

            {team && <div>
                <h1 className="text-accent text-center text-6xl font-semibold">{team.name}</h1>
                <div className="my-12  ">
                    <div className="bg-white p-3 md:p-8 border border-borders">
                        <h2 className="text-xl font-semibold mb-3">Útočníci</h2>
                        <div className="table w-full ">
                            <div className="table-row  child:border-borders text-dark-text child:text-sm">
                                <div className="table-cell w-10"></div>
                                <div className="table-cell">Meno</div>
                                <div className="table-cell"></div>
                                <div className="table-cell text-center w-10">G</div>
                                <div className="table-cell text-center w-10">A</div>
                                <div className="table-cell text-center w-10">B</div>
                                <div className="table-cell text-center">T</div>
                            </div>
                            {players.filter(x => x.role === "att").map((f, i) => (
                                <div className="table-row-group">
                                    {<div key={f.ID} className="table-row child:border-t child:border-borders child:text-lg child:py-3">
                                        <div className="table-cell w-[8%] text-center pr-3">#{f.number}</div>
                                        <div className="table-cell w-1/4 whitespace-pre">{f.first_name} {f.last_name}</div>
                                        <div className="table-cell w-1/3 whitespace-pre"></div>
                                        <div className="table-cell text-center w-10 px-1">{f.goals}</div>
                                        <div className="table-cell text-center w-10 px-1">{f.assists}</div>
                                        <div className="table-cell text-center w-10 px-1">{f.goals + f.assists}</div>
                                        <div className="table-cell w-10 text-center whitespace-pre px-1">{f.penalties}'</div>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="bg-white p-3 md:p-8 border my-16 border-borders">
                        <h2 className="text-xl font-semibold  mb-3">Obrancovia</h2>
                        <div className="table w-full ">
                            <div className="table-row  child:border-borders text-dark-text child:text-sm">
                                <div className="table-cell w-10"></div>
                                <div className="table-cell">Meno</div>
                                <div className="table-cell"></div>
                                <div className="table-cell text-center w-10">G</div>
                                <div className="table-cell text-center w-10">A</div>
                                <div className="table-cell text-center w-10">B</div>
                                <div className="table-cell text-center">T</div>
                            </div>
                            {players.filter(x => x.role === "def").map((f, i) => (
                                <div className="table-row-group">
                                    {<div key={f.ID} className="table-row child:border-t child:border-borders child:text-lg child:py-3">
                                        <div className="table-cell w-[8%] text-center pr-3">#{f.number}</div>
                                        <div className="table-cell w-1/4 whitespace-pre">{f.first_name} {f.last_name}</div>
                                        <div className="table-cell w-1/3 text-dark-text"></div>
                                        <div className="table-cell text-center w-10 px-1">{f.goals}</div>
                                        <div className="table-cell text-center w-10 px-1">{f.assists}</div>
                                        <div className="table-cell text-center w-10 px-1">{f.goals + f.assists}</div>
                                        <div className="table-cell w-10 text-center whitespace-pre px-1">{f.penalties}'</div>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="bg-white p-8 border border-borders ">
                        <h2 className="text-xl font-semibold mb-3">Brankári</h2>
                        <div className="table w-full ">
                            <div className="table-row  child:border-borders text-dark-text child:text-sm">
                                <div className="table-cell w-10"></div>
                                <div className="table-cell">Meno</div>
                                <div className="table-cell"></div>
                                <div className="table-cell w-10 text-center">Z</div>
                                <div className="table-cell w-10 text-center">IG</div>
                                <div className="table-cell w-10 text-center">%</div>
                            </div>
                            {keepers.map((f, i) => (
                                <div className="table-row-group">
                                    {i < 8 && <div key={f.ID} className="table-row child:border-t child:border-borders child:text-lg child:py-3">
                                        <div className="table-cell w-[8%] pr-1 text-center">#{f.number}</div>
                                        <div className="table-cell whitespace-nowrap md:w-1/4">{f.first_name} {f.last_name}</div>
                                        <div className="table-cell w-1/3 text-dark-text"></div>
                                        <div className="table-cell w-10 text-center px-1">{f.interventions}</div>
                                        <div className="table-cell w-10 text-center px-1">{f.recieved_goals}</div>
                                        <div className="table-cell w-10 text-center px-1">{f.recieved_goals > 0 ? Math.round((100 - (f.recieved_goals / f.interventions * 100)) * 100) / 100 : "100"}</div>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                    <h1 className="text-accent text-center text-6xl font-semibold my-8">Zápasy</h1>
                    <div className="flex flex-wrap">
                        {games.map((e, i) => (
                            <SmallGame e={e} className={"w-full md:w-auto min-w-[50%] "} />
                        ))}
                    </div>


                </div>
            </div>}

        </div>
    );
}

export default UserTeam;