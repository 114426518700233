import { Link } from "react-router-dom";
import { format } from "date-fns"
import sk from "date-fns/locale/sk"

const SmallPlayoff = (props, className) => {
    return (
        <div className={`flex border border-borders justify-between p pr-8 py-3 md:py-5  min-w-[50%] ${props.className}  ${props.e.text_b !== "" ? "" : " !bg-white "}`}>
            <div className=" pl-8 flex flex-col justify-center">
                <p className={`py-1 md:pb-3 `} >{props.e.team_a_name ? props.e.team_a_name : props.e.text_a}</p>
                {props.e.text_b !== "" && <p className={`pt-1 md:pt-2 `}>{props.e.text_b}</p>}

            </div>
            {props.e.text_b !== "" && <div className="border-l-2 text-center flex flex-col justify-center text-dark-text w-20">
                <p className="pl-6">{format(new Date(props.e.time), 'eeee', { locale: sk }).charAt(0).toUpperCase() + format(new Date(props.e.time), "eeee", { locale: sk }).slice(1)}</p>
                <p className="pl-6">{props.e.time.substring(props.e.time.indexOf("T") + 1, props.e.time.indexOf("T") + 1 + 5)}</p>
            </div>}

        </div >
    );
}

export default SmallPlayoff;