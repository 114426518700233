import { useEffect, useState } from "react";
import { useYearContext } from "../../hooks/useYearContext";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { sk } from "date-fns/locale";

const UserGameDetail = () => {
    const { id } = useParams()
    const { current } = useYearContext()

    const [game, setGame] = useState();
    const [codes, setCodes] = useState([])

    const [players, setPlayers] = useState([]);
    const [gk_a, setgk_a] = useState()
    const [gk_b, setgk_b] = useState()

    const [showDate, setShowDate] = useState(new Date())
    const [time, setTime] = useState(0)

    //get all match data
    useEffect(() => {
        if (current) {
            const getGroupCodes = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/group/codes?year=${current.year}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    setCodes(json)
                    console.log(json);
                } else {
                    alert(json.error)
                }
            }

            const getPlayers = async (t1, t2) => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/player/match?t1=${t1}&t2=${t2}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    let test = json.filter(x => x.team_id === t1).find(x => x.role === 'gk')
                    if (test) {
                        setgk_a(test.ID)
                    }
                    test = json.filter(x => x.team_id === t2).find(x => x.role === 'gk')
                    if (test) {
                        setgk_b(test.ID)
                    }

                    getGameStats(json)
                } else {
                    alert(json.error)
                }
            }

            const getGameStats = async (players) => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/stats/game/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    players.forEach(e => {
                        e.goals = 0;
                        e.interventions = 0;
                        e.penalties = 0;
                        e.gotGoals = 0
                        e.assists = 0;
                    });
                    json.goals.forEach(e => {
                        players.find(x => x.ID === e.shooter).goals++;
                        if (players.find(x => x.ID === e.assist)) {
                            players.find(x => x.ID === e.assist).assists++;
                        }
                        players.find(x => x.ID === e.keeper).gotGoals++;
                    });
                    json.penalties.forEach(e => {
                        players.find(x => x.ID === e.player).penalties += e.min;
                    });
                    json.interventions.forEach(e => {
                        players.find(x => x.ID === e.player).interventions += e.count
                    });
                    players.forEach(e => {

                        if (e.gotGoals > 0 && e.interventions === 0) {
                            e.interventions += e.gotGoals;
                        }
                    });
                    let temp = players.filter(x => x.goals + x.interventions + x.penalties + x.gotGoals + x.assists !== 0)
                    setPlayers(temp.sort((a, b) => (b.goals + b.assists) - (a.goals + a.assists) || b.goals - a.goals || a.penalties - b.penalties))
                } else {
                    alert(json.error)
                }
            }

            const getGame = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    setGame(json[0])
                    getPlayers(json[0].team_a_id, json[0].team_b_id);
                    setShowDate(new Date(json[0].time))
                    setTime(json[0].time)
                } else {
                    alert(json.error)
                }
            }

            getGame()
            getGroupCodes()
        }


    }, [current])





    return (
        <div className=" w-[90vw] md:w-[70vw] xl:w-[46vw] mx-auto my-12">
            {game && <div className="mx-auto bg-white  border border-borders p-2 lg:p-8">
                <div className="flex justify-around w-full">
                    <TeamInfo status={game.status} team={game.team_a_name} goals={game.team_a_goals} code={game.code_a_name} />
                    {game.status !== 'finished' && <div className={`text-center w-1/5 `}>
                        <div className="text-xl text-dark-text">
                            {game.status === "scheduled" && <h2>{format(showDate, 'eeee', { locale: sk }).charAt(0).toUpperCase() + format(showDate, "eeee", { locale: sk }).slice(1)}</h2>}
                            {game.status === "scheduled" && <h2>{time.substring(time.indexOf("T") + 1, time.indexOf("T") + 1 + 5)}</h2>}
                            {game.status === "live" && <div className="flex relative text-center mx-auto w-min items-center "><div className="absolute -left-4 rounded-full w-2 h-2 bg-accent animate-pulse"></div><h2 className="text-xl text-center text-accent">  Prebieha</h2></div>}
                            {game.status !== 'finished' && <h2 className={" whitespace-nowrap " + (game.status === "live" ? "text-accent" : "")}>Ihrisko {game.field_name}</h2>}
                            <span className="text-xl">
                                {game.text === "group" ? " Skupinový" : ""}
                                {game.text === "o3" ? " O 3. miesto" : ""}
                                {game.text === "o5" ? " O 5. miesto" : ""}
                                {game.text === "o7" ? " O 7. miesto" : ""}
                                {(game.text === "QF1" || game.text === "QF2" || game.text === "QF3" || game.text === "QF4") ? " Štvrťfinále" : ""}
                                {(game.text === "SF1" || game.text === "SF2") ? " Semifinále" : ""}
                                {game.text === "Final" ? " Finále" : ""}
                            </span>
                        </div>
                    </div>}
                    <TeamInfo status={game.status} team={game.team_b_name} goals={game.team_b_goals} code={game.code_b_name} />
                </div>
                <div className="flex justify-between flex-grow-0 ">
                    <ShowGoals players={players.filter(x => x.team_id === game.team_a_id)} status={game.status} />
                    <ShowGoals players={players.filter(x => x.team_id === game.team_b_id)} status={game.status} />
                </div>
                <div className="flex justify-between flex-grow-0 ">
                    <ShowKeeper players={players.filter(x => x.team_id === game.team_a_id && x.interventions + x.gotGoals !== 0)} status={game.status} gk={gk_a} />
                    <ShowKeeper players={players.filter(x => x.team_id === game.team_b_id && x.interventions + x.gotGoals !== 0)} status={game.status} gk={gk_b} />
                </div>
                <div className="flex justify-center ">
                    {(game.best_player && game.status === "finished") &&
                        <div></div>
                    }
                </div>

            </div>}
        </div>
    );
}


const TeamInfo = (props) => {
    return (
        <div className={props.status === 'finished' ? " w-1/2 " : "w-2/5"}>
            <div className="text-center">
                <h1 className="font-bold text-2xl">{props.team ? props.team : props.code}</h1>
                {props.status !== 'scheduled' && <h2 className="font-bold text-2xl mt-5">{props.goals}</h2>}
                {props.status === 'scheduled' && <h2 className="font-bold text-2xl mt-5">#</h2>}
            </div>
        </div>
    )
}

const ShowGoals = (props) => {
    return (
        <div className={`table m-1 lg:m-5 h-min ${props.status === 'finished' ? " w-1/2 " : " w-1/2 "}`}>
            <div className="table-row child:text-dark-text child:border-b child:border-borders">
                <div className="table-cell pl-3">#</div>
                <div className="table-cell"></div>
                <div className="table-cell  w-[10%]">G</div>
                <div className="table-cell w-[10%]">A</div>
                <div className="table-cell text-center w-[10%] pr-3">T</div>
            </div>
            {props.players.filter(x => x.goals + x.penalties + x.assists !== 0).map(e => (
                <div className="table-row child:border-b child:border-borders">
                    <div className="table-cell w-1/6 py-3 pl-1 md:pl-3">{e.number}</div>
                    <div className="table-cell ">{e.first_name.charAt(0)}. {e.last_name}</div>
                    <div className="table-cell w-[10%]">{e.goals}</div>
                    <div className="table-cell w-[10%]">{e.assists}</div>
                    <div className="table-cell text-center w-[10%] pr-1 md:pr-3">{e.penalties}'</div>
                </div>
            ))}

        </div>
    )
}

const ShowKeeper = (props) => {

    return (
        <div className={`m-5 h-min ${props.status === 'finished' ? " w-1/2 " : "w-2/5"}`}>
            {props.players.length > 0 && <div>
                {props.players.map(e => (
                    <div className="">
                        <p className="text-center">{e.first_name} {e.last_name}</p>
                        <div className="text-center">{Math.round((100 - (e.gotGoals / (e.gotGoals + e.interventions) * 100)))}%</div>
                        <div className="flex justify-between items-center">
                            <span>{e.interventions}</span>
                            <div className="w-[80%] rounded-full bg-borders h-2">
                                <div style={{ width: `${(Math.round((100 - (e.gotGoals / (e.gotGoals + e.interventions) * 100)) * 100) / 100)}%` }} className="rounded-full bg-web-gray h-2"></div>
                            </div>
                            <span>{e.gotGoals}</span>
                        </div>
                    </div>
                ))}
            </div>
            }
            {props.players.length < 0 && <div>
                <div className="">
                    <p className="text-center">{props.gk.first_name} {props.gk.last_name}</p>
                    <div className="text-center">100%</div>
                    <div className="flex justify-between items-center">
                        <span>0</span>
                        <div className="w-[80%] rounded-full bg-borders h-2">
                            <div style={{ width: `100%` }} className="rounded-full bg-web-gray h-2"></div>
                        </div>
                        <span>0</span>
                    </div>
                </div>
            </div>
            }

        </div>
    )
}

export default UserGameDetail;