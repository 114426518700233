import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
//pages
import Home from "./pages/Home"
import Admin from "./pages/Admin/Admin"
import Users from './pages/Admin/Users'
import Login from './pages/Admin/Login'
import Signup from './pages/Admin/Signup'
import Tournament from './pages/Admin/Tournament'

import Navbar from "./components/Navbar"
import Footer from './components/Footer';
import Teams from './pages/Admin/Teams'
import Games from './pages/Admin/Games'
import Gallery from './pages/Admin/Gallery'
import EditGame from './pages/Admin/EditGame'
import UserGames from './pages/User/UserGames'
import UserLeaderboard from './pages/User/UserLeaderboard'
import UserStats from './pages/User/UserStats'
import UserHistory from './pages/User/userHistory'
import UserGallery from './pages/User/UserGallery'
import UserSchedule from './pages/User/UserSchedule'
import UserGameDetail from './pages/User/UserGameDetail'
import UserTeam from './pages/User/UserTeam'

function App() {
  const { user } = useAuthContext()
  return (
    <BrowserRouter >
      <main className='min-h-full flex flex-col' >
        <Navbar />
        <Routes className=''>
          <Route index element={<Home />} />
          <Route path="admin" element={user ? <Admin /> : <Navigate to={"/login"} />} />
          <Route path="admin/users" element={user ? <Users /> : <Navigate to={"/login"} />} />
          <Route path="admin/tournament" element={user ? <Tournament /> : <Navigate to={"/login"} />} />
          <Route path="admin/teams" element={user ? <Teams /> : <Navigate to={"/login"} />} />
          <Route path="admin/games" element={user ? <Games /> : <Navigate to={"/login"} />} />
          <Route path="admin/games/:id" element={user ? <EditGame /> : <Navigate to={"/login"} />} />
          <Route path="admin/gallery" element={user ? <Gallery /> : <Navigate to={"/login"} />} />
          <Route path="login" element={!user ? <Login /> : <Navigate to={"/admin"} />} />
          <Route path="signup" element={!user ? <Signup /> : <Navigate to={"/admin"} />} />
          <Route path="games" element={<UserGames />} />
          <Route path="games/:id" element={<UserGameDetail />} />
          <Route path="leaderboard" element={<UserLeaderboard />} />
          <Route path="teams/:id" element={<UserTeam />} />
          <Route path="stats" element={<UserStats />} />
          <Route path="history" element={<UserHistory />} />
          <Route path="gallery" element={<UserGallery />} />
          <Route path="schedule" element={<UserSchedule />} />
        </Routes>
        <Footer />
      </main>
    </BrowserRouter>

  );
}

export default App;
