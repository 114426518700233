const Logo = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="45" viewBox="0 0 33 45" fill="none" className={`${className}`}>
            {/* <path d="M16.5068 45L16.1516 44.8346C16.1106 44.8208 11.9019 42.9464 7.84348 39.9556C2.97888 36.3583 0.0683232 30.5972 0.0546586 24.5329C0.040994 18.0138 0 6.83614 0 6.72588V5.83001L6.47702 5.81623L9.34658 1.77795L12.3118 5.95406L16.5342 0L20.7292 5.94028L23.6807 1.77795L26.5503 5.81623H33L32.959 24.5191C32.9453 30.5972 30.0348 36.3721 25.1565 39.9556C21.1255 42.9464 16.9031 44.8208 16.8621 44.8346L16.5068 45ZM0.273292 6.10567V6.72588C0.273292 6.83614 0.314286 18 0.327951 24.5329C0.341615 30.5145 3.21118 36.193 8.00745 39.7351C12.0385 42.7121 16.2199 44.5727 16.2609 44.5865L16.5068 44.6968L16.7528 44.5865C16.7938 44.5727 20.9888 42.7121 24.9925 39.7351C29.7888 36.193 32.6721 30.5145 32.6857 24.5191L32.7267 6.09188H26.4137L23.6807 2.24655L20.7292 6.40888L16.5342 0.468606L12.3118 6.42266L9.34658 2.24655L6.61366 6.09188L0.273292 6.10567Z" fill="currentColor" /> */}
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8224 37.6263C13.7739 39.7075 16.4932 40.8239 16.4932 40.8239C16.4932 40.8239 19.2124 39.7075 22.164 37.6263C25.8397 35.0352 28.3267 31.1072 29.1329 26.7381L6.31304 32.9127C7.4882 34.7458 9.00497 36.3446 10.8224 37.6263Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.55279 18.5926L29.2012 11.66L23.6807 3.88666L20.7292 8.06277L16.5205 2.1225L12.2981 8.06277L9.33292 3.88666L3.52546 12.0735L3.55279 18.5926Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.9466 7.098L27.9578 8.5176H30.3217C30.3217 10.9847 30.2944 19.2404 30.2807 24.464C30.2671 29.6738 27.8074 34.5804 23.6397 37.6677C20.5789 39.9418 17.436 41.4854 16.4932 41.9265C15.5503 41.4854 12.4074 39.9418 9.34658 37.6539C5.19254 34.5666 2.71925 29.6738 2.70559 24.464C2.69192 19.2542 2.66459 10.9709 2.66459 8.5176H5.0559L6.06708 7.098H1.24347V7.81469C1.24347 7.81469 1.2708 18.2894 1.28447 24.4778C1.29813 30.1424 3.9764 35.4625 8.49938 38.8116C12.2981 41.637 16.2062 43.3736 16.2062 43.3736L16.4932 43.4977L16.7801 43.3736C16.7801 43.3736 20.6882 41.637 24.487 38.8254C29.0099 35.4625 31.6882 30.1424 31.7019 24.4778L31.7429 7.11178L26.9466 7.098Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M29.4335 23.6095L29.4609 12.8453L3.53913 19.8468L3.55279 23.6095C3.56646 26.5314 4.30434 29.3568 5.65714 31.8514L29.3242 25.4563C29.4062 24.8361 29.4335 24.2159 29.4335 23.6095ZM9.89316 29.0122C9.12795 28.9847 8.5677 28.8193 8.19875 28.5023C7.88447 28.2266 7.61118 27.7029 7.35155 26.9311L5.62981 21.6661L5.30186 21.0459L7.81615 20.3706L8.07577 21.4732L9.55155 26.3798L11 20.5498L10.8907 20.0811L10.5627 19.6125L12.5714 19.075L9.89316 29.0122ZM20.7019 25.4839C20.7975 25.6906 20.9205 25.8698 21.0708 25.9939L17.9416 26.8484L18.3242 26.1317C18.3789 25.9112 18.4062 25.5666 18.4062 25.098V22.562L15.8919 23.2511V25.7871C15.8919 26.2971 15.9192 26.6279 15.9876 26.7932L16.3429 27.2894L13.2273 28.1302C13.3776 27.8959 13.5006 27.6615 13.5963 27.441C13.6509 27.1516 13.6919 26.7932 13.6919 26.3798V20.4257C13.6919 19.9847 13.6646 19.6263 13.5963 19.3782C13.5006 19.1853 13.3776 19.0337 13.2273 18.9234L16.3429 18.0827C16.1652 18.3032 16.0559 18.5237 15.9876 18.7442C15.9192 18.9648 15.8919 19.3231 15.8919 19.8331V21.8177L18.4062 21.1286V19.1302C18.4062 18.6202 18.3789 18.2894 18.3242 18.124C18.2559 17.9311 18.1329 17.7657 17.9416 17.6279L21.0571 16.7871C20.8795 17.0352 20.7565 17.2695 20.7019 17.4762C20.6335 17.683 20.6062 18.0276 20.6062 18.5375V24.4916C20.6062 24.9602 20.6335 25.291 20.7019 25.4839ZM27.3155 17.1593L26.8373 16.9112L26.1677 16.9525L25.8944 17.049L25.6894 17.1317V23.1133C25.6894 23.5681 25.7304 23.9127 25.7988 24.1194L26.1404 24.6156L23.0248 25.4563C23.2161 25.1531 23.3391 24.9188 23.3801 24.7672C23.4348 24.5605 23.4758 24.2159 23.4758 23.706V17.7243L23.0795 17.7932L22.3006 18.1378L21.4944 18.7305L21.9453 16.539L27.7255 14.9678L27.3155 17.1593Z" fill="currentColor" />
        </svg>
    );
}

export default Logo;