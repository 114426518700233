import { useState } from "react"

const GameScores = (props) => {



    const [displayGoalForm, setDisplayGoalForm] = useState(false)
    const [displayPenaltyForm, setDisplayPenaltyForm] = useState(false)

    //#region GOALS



    const removeGoal = async (id) => {
        props.setIsPending(true)
        let team_a_goals = props.game.team_a_goals
        let team_b_goals = props.game.team_b_goals;
        if (props.thisTeam === 'a') {
            team_a_goals -= 1
        } else {
            team_b_goals -= 1
        }
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/stats/goal/${id}`, {
            method: 'DELETE',
            body: JSON.stringify({ game_id: props.game.ID, team_a_goals, team_b_goals }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            props.setGoals(props.goals.filter(x => x.ID !== id))

            if (props.thisTeam === 'a') {
                props.setGame({ ...props.game, team_a_goals: props.game.team_a_goals - 1 })
            } else {
                props.setGame({ ...props.game, team_b_goals: props.game.team_b_goals - 1 })
            }
        } else {

            alert(json.error)
        }
        props.setIsPending(false)
    }

    let [conf, setConf] = useState(-1)


    function findGoalNames(e, players) {
        let temp = players.find(x => x.ID === e.shooter)
        let shooter, assister;
        if (temp) {
            shooter = `#${temp.number} ${temp.first_name.charAt(0)}. ${temp.last_name}(${temp.role})`
        } else {
            shooter = `Error`
        }

        temp = players.find(x => x.ID === e.assist)
        if (temp) {
            assister = ` + #${temp.number} ${temp.first_name.charAt(0)}. ${temp.last_name}(${temp.role})`
        } else {
            assister = ``
        }


        return (
            <div className="bg-borders p-2 pr-0 m-1 child:text-lg flex flex-col w-full items-center ">
                <div className="child:text-lg">
                    <span>{shooter}</span>
                    <span>{assister}</span>
                </div>


                <div className=" mx-2">
                    {conf !== e.ID && <button className="btn  z-50" onClick={x => setConf(e.ID)}>Delete</button>}
                    {conf == e.ID &&
                        <div className="flex">
                            <button className="btn !bg-transparent border border-accent !text-accent mr-5 " onClick={e => setConf(-1)}>Cancel</button>
                            <button className="btn " onClick={x => { removeGoal(e.ID); setConf(-1) }}>Delete</button>

                        </div>
                    }
                </div>
            </div>
        )
    }
    //#endregion


    //#region PENALTIES



    const removePenalty = async (id) => {
        props.setIsPending(true)
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/stats/penalty/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            props.setPenalties(props.penalties.filter(x => x.ID !== id))
        } else {
            alert(json.error)
        }
        props.setIsPending(false)
    }
    let [confPen, setConfPen] = useState(-1)
    function findPenaltyNames(e) {
        let temp = props.players.find(x => x.ID === e.player)
        let player;
        if (temp) {
            player = `(${temp.number}) ${temp.first_name.charAt(0)}. ${temp.last_name} - ${e.min}min.`
        } else {
            player = `Error`
        }


        return (
            <div className="bg-borders p-2 pr-0 m-1 child:text-lg flex flex-col items-center ">
                <div>{player}</div>
                <div className=" mx-2">
                    {confPen !== e.ID && <button className="btn  z-50" onClick={x => setConfPen(e.ID)}>Delete</button>}
                    {confPen == e.ID &&
                        <div className="flex">
                            <button className="btn !bg-transparent border border-accent !text-accent mr-5 " onClick={e => setConfPen(-1)}>Cancel</button>
                            <button className="btn " onClick={x => { removePenalty(e.ID); setConfPen(-1) }}>Delete</button>

                        </div>
                    }
                </div>
            </div>
        )
    }
    //#endregion

    //#region Interventions
    const addIntervention = async () => {
        props.setIsPending(true)
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/stats/intervention`, {
            method: 'POST',
            body: JSON.stringify({ player: props.myGK, game_id: props.game.ID }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            let temp = props.interventions;
            if (temp.find(x => x.player === props.myGK)) {
                temp.find(x => x.player === props.myGK).count++
            } else {
                temp.push({ player: props.myGK, count: 1 })
            }
            props.setInterventions([...temp])
        } else {

            alert(json.error)
        }
        props.setIsPending(false)
    }

    const removeIntervention = async () => {
        props.setIsPending(true)
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/stats/intervention?game_id=${props.game.ID}&player=${props.myGK}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            let temp = props.interventions;
            if (temp.find(x => x.player === props.myGK) && temp.find(x => x.player === props.myGK).count > 0) {
                temp.find(x => x.player === props.myGK).count--;
            }

            props.setInterventions([...temp])
        } else {

            alert(json.error)
        }
        props.setIsPending(false)
    }

    //#endregion

    return (
        <div className="w-1/2">

            <div className=" mt-8 ">
                <div className=" flex justify-around mt-8 border-y items-center">
                    <label className="text-xl">Brankár:</label>
                    <select className=" py-3 text-lg" value={props.myGK} onChange={x => { x.target.value !== 'x' ? props.setMyGK(parseInt(x.target.value)) : props.setMyGK(undefined) }}>
                        <option value={'x'}></option>
                        {props.players.map(e => (
                            <option className="justify-between flex" key={e.ID} value={e.ID}>#{e.number} - {e.first_name} {e.last_name} {e.role === "gk" ? `(${e.role})` : ""} </option>
                        ))}
                    </select>
                </div>

                {(props.myGK && props.otherGK) && <div >
                    <div className=" py-5">
                        <h3 className="text-2xl text-center">Pridať</h3>
                        <div className="flex justify-around">
                            <button className="btn w-2/5 rounded-sm text-3xl !py-3" onClick={e => setDisplayGoalForm(true)}>Gól</button>
                            {displayGoalForm && <AddGoalForm goals={props.goals} setGoals={props.setGoals} setGame={props.setGame} myTeamID={props.myTeamID} players={props.players} otherGK={props.otherGK} thisTeam={props.thisTeam} team_name={props.team_name} setDisplayGoalForm={setDisplayGoalForm} game={props.game} user={props.user} />}
                            <button className="btn w-1/3 rounded-sm text-3xl !py-3" onClick={e => setDisplayPenaltyForm(true)}>Trest</button>
                            {displayPenaltyForm && <AddPenaltyForm penalties={props.penalties} setPenalties={props.setPenalties} players={props.players} team_name={props.team_name} setDisplayPenaltyForm={setDisplayPenaltyForm} gameID={props.game.ID} user={props.user} />}

                        </div>

                    </div>

                    <div className="border-b">

                        <div className="flex w-full justify-center h-16 items-center">
                            <button disabled={props.isPending} className="w-[30%] bg-accent text-center text-3xl font-bold h-full text-white flex items-center justify-center cursor-pointer select-none rounded-sm" onClick={removeIntervention}>-</button>
                            <div className="w-1/3">
                                <h3 className="text-2xl mb-1 text-center">Zákroky</h3>
                                <div className="child:text-2xl child:font-bold flex justify-center">
                                    {!props.interventions.find(x => x.player === props.myGK) && <div>0</div>}
                                    {props.interventions.find(x => x.player === props.myGK) && <div>{props.interventions.find(x => x.player === props.myGK).count} </div>}
                                </div>
                            </div>


                            <button disabled={props.isPending} className="w-[30%] bg-accent text-center text-3xl font-bold h-full text-white flex items-center justify-center cursor-pointer select-none rounded-sm" onClick={addIntervention} >+</button>
                        </div>
                    </div>

                    <h3 className="text-2xl">Góly</h3>
                    <div className="w-min whitespace-nowrap">
                        {props.goals.filter(x => x.team_id === props.myTeamID).map(e => (
                            <div key={e.ID}>{findGoalNames(e, props.players)}</div>
                        ))}
                    </div>
                    <h3 className="text-2xl">Tresty</h3>
                    <div className="flex flex-wrap">
                        {props.penalties.filter(x => x.team_id === props.myTeamID).map(e => (
                            <div key={e.ID}>{findPenaltyNames(e)}</div>
                        ))}
                    </div>


                </div>}
            </div>

        </div>
    );
}

export default GameScores;

const AddGoalForm = (props) => {

    const [shooter, setShooter] = useState('x')
    const [assister, setAssister] = useState('x')

    const [isPending, setIsPending] = useState(false)

    const addGoal = async (e) => {
        e.preventDefault();
        if (shooter === 'x') {
            alert("Zadaj strelca gólu!!")
            return
        }
        setIsPending(true)
        let team_a_goals = props.game.team_a_goals
        let team_b_goals = props.game.team_b_goals;
        if (props.thisTeam === 'a') {
            team_a_goals += 1
        } else {
            team_b_goals += 1
        }
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/stats/goal`, {
            method: 'POST',
            body: JSON.stringify({ game_id: props.game.ID, team_id: props.myTeamID, shooter, assist: assister, keeper: props.otherGK, team_a_goals, team_b_goals }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            props.setGoals([...props.goals, json[0]])
            if (props.thisTeam === 'a') {
                props.setGame({ ...props.game, team_a_goals: props.game.team_a_goals + 1 })

            } else {
                props.setGame({ ...props.game, team_b_goals: props.game.team_b_goals + 1 })
            }
            setShooter('x')
            setAssister('x')

        } else {

            alert(json.error)
        }
        setIsPending(false)
        props.setDisplayGoalForm(false)
    }

    return (
        <div className="fixed top-16 p-8 left-0 w-screen h-screen bg-[#f8f8f8]">
            <h3 className="text-2xl text-center">Pridať gól pre {props.team_name}</h3>
            <div className=" flex flex-1 flex-col justify-around mt-1" >
                <div className="flex w-full justify-around my-8 items-center">

                    <select className="p-3 text-xl" value={shooter} onChange={x => { x.target.value !== 'x' ? setShooter(parseInt(x.target.value)) : setShooter('x') }}>
                        <option value={'x'}>- Strelec -</option>
                        {props.players.sort((a, b) => a.number - b.number).map(e => (
                            <option key={e.ID} value={e.ID}>({e.number}) {e.first_name} {e.last_name}</option>
                        ))}
                    </select>
                    <div className="text-2xl">+</div>
                    <select className=" p-3 text-xl" value={assister} onChange={x => { x.target.value !== 'x' ? setAssister(parseInt(x.target.value)) : setAssister('x') }}>
                        <option value={'x'}>- Asistent - </option>
                        {props.players.sort((a, b) => a.number - b.number).map(e => (
                            <option key={e.ID} value={e.ID}>({e.number}) {e.first_name} {e.last_name}</option>
                        ))}
                    </select>
                </div>


                <div className="flex justify-center items-center">
                    <button disabled={isPending} className="btn !bg-transparent border border-accent !text-accent text-3xl" onClick={e => props.setDisplayGoalForm(false)}>Zrušiť</button>
                    <button disabled={isPending} className="btn  text-3xl w-min ml-10" onClick={e => addGoal(e)} >Pridať</button>
                </div>

            </div>
        </div>
    )
}

const AddPenaltyForm = (props) => {



    const [penalized, setPenalized] = useState('x')
    const [penaltyLength, setPenaltyLength] = useState('x')

    const [isPending, setIsPending] = useState(false)

    const addPenalty = async () => {
        let player, min;
        if (penalized === 'x' || penaltyLength === 'x') {
            alert("Zadaj trestaného hráča a dĺžku trestu!!")
            return
        }
        player = penalized;
        min = penaltyLength

        setIsPending(true)
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/stats/penalty`, {
            method: 'POST',
            body: JSON.stringify({ game_id: props.gameID, player, min }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            props.setPenalties([...props.penalties, json[0]])
            setPenalized('x')
            setPenaltyLength('x')


        } else {

            alert(json.error)
        }
        setIsPending(false)
        props.setDisplayPenaltyForm(false)
    }

    return (
        <div className="fixed top-16 p-8 left-0 w-screen h-screen bg-[#f8f8f8]">
            <h3 className="text-2xl text-center">Pridať tresty pre {props.team_name}</h3>
            <div className=" flex flex-1 flex-col justify-around mt-1" >
                <div className="flex w-full justify-around my-8 items-center">
                    <select className=" px-3 py-2 text-lg" value={penalized} onChange={x => { x.target.value !== 'x' ? setPenalized(parseInt(x.target.value)) : setPenalized('x') }}>
                        <option value={'x'}>- Trestaný -</option>
                        {props.players.sort((a, b) => a.number - b.number).map(e => (
                            <option key={e.ID} value={e.ID}>({e.number}) {e.first_name} {e.last_name}</option>
                        ))}
                    </select>

                    <select className=" px-3 py-2 text-lg" value={penaltyLength} onChange={x => { x.target.value !== 'x' ? setPenaltyLength(parseInt(x.target.value)) : setPenaltyLength('x') }}>
                        <option value={'x'}>- Dĺžka trestu -</option>
                        <option value={1}>(MT)1 min.</option>
                        <option value={3}>(VT)3 min.</option>
                        <option value={5}>(OT)5 min.</option>
                    </select>
                </div>


                <div className="flex justify-center items-center">
                    <button disabled={isPending} className="btn !bg-transparent border border-accent !text-accent text-3xl" onClick={e => props.setDisplayPenaltyForm(false)}>Zrušiť</button>
                    <button disabled={isPending} className="btn  text-3xl w-min ml-10" onClick={e => addPenalty()} >Pridať</button>
                </div>

            </div>
        </div>
    )
}